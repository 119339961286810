import React, { useState } from "react";
import styled from "styled-components";
import { Header } from "antd/es/layout/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SettingsIcon } from "../img/Settings.svg";
import { ReactComponent as UserAvatar } from "../img/UserAvatar.svg";
import { Dropdown } from "antd";
import { useAuth } from "../providers/AuthProvider";
import { logoutUser } from "../services/authService";
import Profile from "./Modals/Profile";

const MobileHeaderStyles = styled(Header)`
  display: flex;
  align-items: center;
  background: #050505;
  padding-inline: 30px;
  padding-block: 40px;

  button {
    border: none;
    background: none;
    cursor: pointer;

    svg {
      display: block;
    }
  }

  a {
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-inline: auto;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

export default function MobileHeader({ style }: any) {
  const navigate = useNavigate();
  const { userDetails, removeAuth } = useAuth();
  const location = useLocation();
  const [profile, setProfile] = useState(false);
  const handleLogout = async () => {
    try {
      if (userDetails) {
        await logoutUser();
        removeAuth();
      }
    } catch (e) {
      console.error(e);
    } finally {
      navigate("/", { replace: true });
    }
  };


  return (
    <MobileHeaderStyles style={style}>
      {userDetails?.email && (
        <button
          onClick={() => {
            navigate("/settings");
          }}
        >
          <SettingsIcon />
        </button>
      )}
      <Link to={"/"}>AskYourPDF</Link>
      {userDetails?.email && (
        <Dropdown
          menu={{
            items: [
              {
                key: 1,
                label: (
                  <div
                    onClick={() => {
                      setProfile(!profile);
                    }}
                    style={{ fontWeight: "700" }}
                  >
                    Profile
                  </div>
                ),
              },
              {
                key: 2,
                label: (
                  <Link to={"/apikeys"} style={{ fontWeight: "700" }}>
                    Developers
                  </Link>
                ),
              },
              {
                key: 3,
                label: (
                  <Link to={"/generate-id"} style={{ fontWeight: "700" }}>
                    ID Generator
                  </Link>
                ),
              },
              {
                key: 4,
                label: (
                  <div onClick={handleLogout} style={{ fontWeight: "700" }}>
                    Logout
                  </div>
                ),
              },
            ],
            style: { fontFamily: "Satoshi, sans-serif" },
          }}
          trigger={["click"]}
        >
          <button>
            <UserAvatar />
          </button>
        </Dropdown>
      )}
      <Profile
        profile={profile}
        setProfile={setProfile}
        currentPath={location.pathname}
      />
    </MobileHeaderStyles>
  );
}
