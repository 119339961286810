import { Header } from "antd/es/layout/layout";
import React, { useState } from "react";
import styled from "styled-components";
import { useAuth } from "../providers/AuthProvider";
import { PAID_ROLES, UNPAID_ROLES } from "../config/config";
import PaymentModal from "./Modals/PaymentModal";
import { getUserRole } from "../utils/utils";

const AppHeaderStyles = styled(Header)`
  display: none;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
  padding-inline: 26px;

  h1 {
    color: #161616;
    font-size: 14px;
    font-family: Franie, sans-serif;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin: 0;
    text-transform: uppercase;
  }

  button {
    border-radius: 7.95px;
    background: #000;
    border: none;
    cursor: pointer;
    padding: 10.6px 15.9px;
    width: 106px;

    color: #edb01a;
    font-size: 14px;
    font-family: Satoshi, sans-serif;
    font-weight: 700;
    line-height: 15.9px;
    letter-spacing: -0.28px;
  }

  @media (min-width: 992px) {
    display: flex;
  }
`;

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const AccountBadge = styled.div`
  padding: 2px 8px;
  border-radius: 16px;
  background: #ecfdf3;

  color: #027a48;
  text-align: center;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 18px;
`;

export default function AppHeader() {
  const { userDetails } = useAuth();
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const userRole = getUserRole(userDetails);

  return (
    <AppHeaderStyles>
      <HeaderLogo>
        <h1>AskYourPDF</h1>
        <AccountBadge>
          {userRole.charAt(0).toUpperCase() + userRole.slice(1)}
        </AccountBadge>
      </HeaderLogo>
      {userDetails?.email !== undefined &&
        userRole === "free" && (
          <button
            onClick={() => {
              setShowPaymentModal(true);
            }}
          >
            Upgrade
          </button>
        )}
      <PaymentModal open={showPaymentModal} setOpen={setShowPaymentModal} />
    </AppHeaderStyles>
  );
}
