import {AUTH_SERVER_URL, CURRENT_ENV, INCLUDE_CREDENTIALS} from "../config/config";

export async function createAnonymousUser(signature: string) {
  try {
    const res = await fetch(`${AUTH_SERVER_URL}/create-anon-user`, {
      method: "POST",
      body: JSON.stringify({ signature }),
      headers: { "Content-Type": "application/json" },
    });

    const j = await res.json();

    if (!res.ok) {
      throw new Error(JSON.stringify(j));
    }

    return j;
  } catch (err) {
    throw err;
  }
}

export async function currentUser(token?: string) {
  try {
    const res = await fetch(`${AUTH_SERVER_URL}/users/me`, {
      method: "GET",
      credentials: INCLUDE_CREDENTIALS,
      headers: { Authorization: `Bearer ${CURRENT_ENV === "production" ? token : localStorage.getItem("token")}`},
    });

    const j = await res.json();

    if (!res.ok) {
      throw new Error(JSON.stringify(j));
    }

    return j;
  } catch (err) {
    console.error(err);
  }
}

export async function logoutUser() {
  try {
    const res = await fetch(`${AUTH_SERVER_URL}/auth/cookie/logout`, {
      method: "POST",
      credentials: INCLUDE_CREDENTIALS,
    });
    if (!res.ok) {
      const j = res.json();
      throw new Error(JSON.stringify(j));
    }
    return { status: "success" };
  } catch (err) {
    console.error(err);
  }
}
