import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Alert, Dropdown, Layout, Slider, Table } from "antd";
import AppSidebar from "../components/AppSidebar";
import DeveloperSidebar from "../components/DeveloperSidebar";
import { Content } from "antd/es/layout/layout";
import {
  ArrowLeft,
  ArrowUpRight,
  CaretDown,
  Check,
  Warning,
  X,
} from "@phosphor-icons/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MastercardLogo } from "../img/MastercardLogo.svg";
import Column from "antd/es/table/Column";
import {formatDate, formatPlan, getAPIRole} from "../utils/utils";
import UpdatePaymentMethodModal from "../components/Modals/UpdatePaymentMethodModal";
import { useSubscription } from "../providers/SubscriptionProvider";
import Spinner from "../components/Spinner";
import { API_SUBSCRIPTION_PLAN_TYPE, DEV_PLAN_CONFIG, UserAPIPlan } from "../config/config";
import { useAuth } from "../providers/AuthProvider";
import CardBrandIcon from "../components/CardBrandIcon";
import { useMutation } from "react-query";
import { ActionType, SubType, manageSubscription } from "../services/payment";
import { alerts } from "../utils/alerts";

const DevContent = styled(Content)`
  padding-inline: 20px;
  padding-top: 15px;
  padding-bottom: 45px;
  background: #ffffff;

  @media (min-width: 576px) {
    padding-inline: 40px;
  }

  @media (min-width: 992px) {
    padding-block: 45px;
    padding-inline: 40px;
  }
  @media (min-width: 1280px) {
    padding-inline: 110px;
  }
`;

const TitleSection = styled.section`
  display: none;

  h1 {
    color: #141718;
    font-family: Satoshi, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1.6px;
  }

  a {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid var(--black, #000);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #000;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const Section = styled.section`
  padding-bottom: 20px;
  border-bottom: solid 1px #eaecf0;

  @media (min-width: 992px) {
    margin-top: 50px;
  }
`;

const PaymentMethodSection = styled(Section)`
  margin-top: 25px;

  .ant-alert {
    padding: 16px;
    margin-top: 16px;
  }

  .ant-alert-error {
    border: 1px solid #fda29b;
    background: #fffbfa;

    .ant-alert-message,
    .ant-alert-description {
      color: #b42318;
    }
  }

  .ant-alert-with-description {
    .ant-alert-message {
      font-family: Satoshi, sans-serif;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .ant-alert-description {
      font-family: Satoshi, sans-serif;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      font-weight: 400;
    }
  }

  @media (min-width: 992px) {
    margin-top: 36px;
  }
`;

const PlanActionButton = styled.button<{ $alt?: boolean }>`
  padding: 8px 14px;
  border-radius: 8px;
  border: ${(props) => (props.$alt ? "1.5px solid #000" : "1px solid #000")};
  background: ${(props) => (props.$alt ? "#FFFFFF" : "#000")};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: ${(props) => (props.$alt ? "#000000" : "#FFFFFF")};
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ContentLayout = styled(Layout)`
  @media (min-width: 992px) {
    margin-left: 362px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background: #050505;
  padding-inline: 30px;
  padding-block: 30px;

  position: sticky;
  top: 0;
  z-index: 100;

  button {
    border: none;
    background: none;
    cursor: pointer;

    svg {
      display: block;
    }
  }

  p {
    color: #fff;
    font-family: Satoshi, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-left: 22px;
  }

  a {
    margin-left: auto;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #ffffff;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

const MobilePageSwitcher = styled.button`
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  width: 100%;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (min-width: 992px) {
    display: none;
  }
`;

const PlanBadge = styled.div<{ $color?: string; $background?: string }>`
  border-radius: 16px;
  padding: 2px 10px;
  background: ${(props) => (props.$background ? props.$background : "#F2F4F7")};
  color: ${(props) => (props.$color ? props.$color : "#344054")};
  text-align: center;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const InvoiceSection = styled.div`
  margin-top: 25px;

  .ant-table-thead > tr > th {
    padding: 12px 24px;
    background: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    border-top: 1px solid #eaecf0;

    color: #475467;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    &:first-child {
      border-left: 1px solid #eaecf0;
      border-start-start-radius: 12px;
    }

    &:last-child {
      border-right: 1px solid #eaecf0;
      border-start-end-radius: 12px;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 16px 24px;
    color: #101828;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-bottom: 1px solid #eaecf0;

    &:first-child {
      font-weight: 700;
      border-left: 1px solid #eaecf0;
    }

    &:last-child {
      font-weight: 500;
      border-right: 1px solid #eaecf0;
    }
  }

  .ant-table-tbody > tr:last-child {
    & > td:first-child {
      border-end-start-radius: 12px;
    }

    & > td:last-child {
      border-end-end-radius: 12px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f9fafb;
  }

  @media (min-width: 992px) {
    margin-top: 24px;
  }
`;

const InvoiceHead = styled.div`
  margin-bottom: 29px;
  font-family: Satoshi, sans-serif;
  font-style: normal;

  h2 {
    color: #101828;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
  }

  p {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    span {
      font-weight: 700;
    }
  }
`;

const PlanItem = styled.div<{ $active?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.$active ? "1fr auto" : "1fr")};
  align-items: flex-start;
  gap: 4px;
  background: ${(props) => (props.$active ? "#f9fafb" : "ffffff")};

  ${PlanBadge} {
    width: fit-content;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr auto;
  }
`;

const PlanItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .ant-slider {
    max-width: 520px;
    margin-top: 0;

    .ant-slider-rail {
      height: 8px;
      border-radius: 4px;
      background: #f3f3f3;
    }

    .ant-slider-track {
      height: 8px;
      border-radius: 4px;
      background: #000;
    }

    .ant-slider-handle {
      transform: translateX(-50%) translateY(-50%) !important;
      inset-inline-start: -1px;
      inset-block-start: -1px;

      &:focus,
      &:hover {
        &::after {
          inset-inline-start: unset;
          inset-block-start: unset;
        }
      }

      &::after {
        width: 26px;
        height: 26px;
        background: #404040;
        box-shadow: unset;
      }
    }
  }

  h1 {
    color: #2c2c2c;
    font-family: Satoshi, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
  }

  .plan__head {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      color: #101828;
      font-family: Satoshi, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .plan__benefits {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
    p {
      color: #141718;
      font-family: Satoshi, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      span {
        font-weight: 400;
      }
    }

    p#enterprise__plan__text {
      margin-top: 8px;
      color: #475467;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.28px;
    }
  }

  @media (min-width: 992px) {
    gap: 4px;
  }
`;

const PlanItemButtons = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  margin-top: 12px;

  @media (min-width: 992px) {
    margin-top: unset;
  }
`;

const PaymentMethodContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  gap: 22px;

  button {
    justify-self: start;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr auto;
    gap: unset;
  }
`;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Satoshi, sans-serif;
  font-style: normal;

  h2 {
    color: #101828;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
  }

  p {
    color: #475467;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    span {
      font-weight: 700;
    }
  }

  .card__details {
    display: flex;
    align-items: center;
    gap: 7px;

    @media (min-width: 768px) {
      flex-direction: column;
      align-items: unset;
      gap: 16px;
    }
  }
`;

const StatusBadge = styled.div<{ $paid: boolean }>`
  display: flex;
  align-self: flex-start;
  padding: 2px 8px 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;

  color: ${(props) => (props.$paid ? "#067647" : "#b42318")};
  border: ${(props) =>
    props.$paid ? "1.5px solid #079455" : "1.5px solid #D92D20"};
  text-align: center;
  font-family: Satoshi, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(8px);
  z-index: 10;
  height: 100%;
  align-items: center;

  @media (min-width: 992px) {
    margin-left: 180px;
  }
`;

export default function BillingInfo() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] =
    useState(false);

  const { userDetails, userToken } = useAuth();

  const [isCancelling, setIsCancelling] = useState(false);
  const [isManaging, setIsManaging] = useState(false);

  const {
    devSubscriptionInfo: subscriptionInfo,
    subscriptionStatus,
    isSubscriptionError,
    subscriptionError,
    billingHistory,
    billingStatus,
    isBillingFetching,
    totalBillingRecords,
    setBillingPage,
    billingPageSize,
  } = useSubscription();

  const is404Error = isSubscriptionError && subscriptionError.status === 404;
  const isSubscriptionCanceled = subscriptionInfo ? subscriptionInfo.subscription_status === "canceled" && new Date().getTime() < new Date(subscriptionInfo.renewal_date*1000).getTime() : false;

  const apiBillingHistory = billingHistory?.history?.filter(
    (history: any) => history.plan_type === API_SUBSCRIPTION_PLAN_TYPE
  );

  const API_SUBSCRIPTION_TYPE = SubType.API;

  useEffect(() => {
    if (isSubscriptionError && !is404Error) {
      if (subscriptionError.message) {
        alerts.error("Failed to load subscriptions", `${subscriptionError.message}`);
      } else {
        alerts.error("Failed to load subscriptions",
          "An error occurred while loading your subscription. Please try again",
        );
      }
    }
  }, [isSubscriptionError, subscriptionError]);

  const currentPlan = DEV_PLAN_CONFIG[getAPIRole(userDetails)];

  const manageSubscriptionMutation = useMutation(manageSubscription, {
    onSuccess: (data) => {
      window.location.href = data.url;
    },
    onError: (error) => {
      alerts.error("Error", "An error occurred. Please try again");
    },
    onSettled: () => {
      setIsCancelling(false);
      setIsManaging(false);
    },
  });

  const handleManageSubscription = async (subType: SubType, action?: ActionType) => {
    if (subscriptionInfo === undefined || userToken === undefined) {
      return;
    }

    manageSubscriptionMutation.mutate({token: userToken, subType, action});
  };

  return (
    <Layout hasSider={true} style={{ minHeight: "100vh" }}>
      {subscriptionStatus === "loading" && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
      <AppSidebar />
      <DeveloperSidebar />
      <ContentLayout>
        <Header>
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowLeft size={20} color="#ffffff" />
          </button>
          <p>Developers</p>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://docs.askyourpdf.com/askyourpdf-docs/"
          >
            View Docs
            <ArrowUpRight size={20} color="#ffffff" />
          </a>
        </Header>
        <DevContent>
          <TitleSection>
            <h1>Billing</h1>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.askyourpdf.com/api/"
            >
              View Documentation
              <ArrowUpRight size={20} color="#000000" />
            </a>
          </TitleSection>
          <Section>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 1,
                    label: (
                      <Link to="/apikeys" replace={true}>
                        API Keys
                      </Link>
                    ),
                  },
                  {
                    key: 2,
                    label: (
                      <Link to="/billing" replace={true}>
                        Billing
                      </Link>
                    ),
                  },
                ],
                style: { fontFamily: "Inter, sans-serif" },
              }}
              trigger={["click"]}
            >
              <MobilePageSwitcher>
                {location.pathname === "/apikeys" ? "API Keys" : "Billing"}
                <CaretDown size={20} color="#667085" />
              </MobilePageSwitcher>
            </Dropdown>
            <PlanItem>
              <PlanItemInfo>
                <div className="plan__head">
                  <p>Current Plan</p>
                  <PlanBadge $color="#027A48" $background="#ECFDF3">
                    {currentPlan.name} Plan
                  </PlanBadge>
                  {isSubscriptionCanceled && <PlanBadge>Canceled</PlanBadge>}
                </div>
                <h1>${currentPlan.price}/month</h1>
                <div className="plan__benefits">
                  <p>
                    {currentPlan.messages} messages <span>monthly</span>
                  </p>
                  <p>|</p>
                  <p>
                    {currentPlan.pages} document pages <span>monthly</span>
                  </p>
                </div>
              </PlanItemInfo>
              <PlanItemButtons>
                <PlanActionButton
                  onClick={() => {
                    navigate("/billing/upgrade");
                  }}
                >
                  {currentPlan.name ===
                  DEV_PLAN_CONFIG[UserAPIPlan.API_ENTERPRISE].name
                    ? "Switch Plan"
                    : "Upgrade Plan"}
                </PlanActionButton>
                {currentPlan.name !==
                  DEV_PLAN_CONFIG[UserAPIPlan.API_FREE].name &&
                  (isSubscriptionCanceled ? (
                    <PlanActionButton
                      onClick={() => {
                        setIsCancelling(true);
                        handleManageSubscription(API_SUBSCRIPTION_TYPE);
                      }}
                      disabled={manageSubscriptionMutation.isLoading}
                      $alt={true}
                    >
                      Renew Plan{" "}
                      {isCancelling && (
                        <Spinner
                          style={{ width: "24px", verticalAlign: "middle" }}
                        />
                      )}
                    </PlanActionButton>
                  ) : (
                    <PlanActionButton
                      onClick={() => {
                        setIsCancelling(true);
                        handleManageSubscription(API_SUBSCRIPTION_TYPE, ActionType.SUBSCRIPTION_CANCEL);
                      }}
                      disabled={manageSubscriptionMutation.isLoading}
                      $alt={true}
                    >
                      Cancel Subscription{" "}
                      {isCancelling && (
                        <Spinner
                          style={{ width: "24px", verticalAlign: "middle" }}
                        />
                      )}
                    </PlanActionButton>
                  ))}
              </PlanItemButtons>
            </PlanItem>
          </Section>
          {subscriptionStatus !== "error" && subscriptionInfo && (
            <PaymentMethodSection>
              <PaymentMethodContainer>
                <PaymentMethod>
                  <h2>Payment Method</h2>
                  <div className="card__details">
                    <CardBrandIcon
                      cardBrand={subscriptionInfo.card_brand}
                      style={{ width: "48px" }}
                    />
                    <p>
                      {subscriptionInfo.card_brand.charAt(0).toUpperCase() +
                        subscriptionInfo.card_brand.slice(1)}{" "}
                      ending in <span>*{subscriptionInfo.card_last_four}</span>
                    </p>
                  </div>
                </PaymentMethod>
                <PlanActionButton
                  $alt={true}
                  onClick={() => {
                    setIsManaging(true);
                    handleManageSubscription(API_SUBSCRIPTION_TYPE, ActionType.PAYMENT_UPDATE);
                  }}
                  disabled={manageSubscriptionMutation.isLoading}
                >
                  {isManaging ? (
                    <>
                      Please Wait{" "}
                      <Spinner
                        style={{ width: "18px", verticalAlign: "middle" }}
                      />
                    </>
                  ) : (
                    "Manage Payment Method"
                  )}
                </PlanActionButton>
              </PaymentMethodContainer>
              {subscriptionInfo.subscription_status === "past_due" && (
                <Alert
                  message="Update your payment method"
                  description="We tried to renew your subscription but we couldn't because of a payment issue, please check your payment method to avoid service disruption"
                  type="error"
                  showIcon
                  icon={<Warning size={20} weight="bold" color="#D92D20" />}
                />
              )}
            </PaymentMethodSection>
          )}
          <InvoiceSection>
            <InvoiceHead>
              <h2>Invoice History</h2>
              <p>
                Please reach out to our team via{" "}
                <span>billing@askyourpdf.com</span> if you have any concerns
                about billing
              </p>
            </InvoiceHead>
            <Table
              dataSource={apiBillingHistory}
              pagination={{
                defaultCurrent: 1,
                total: totalBillingRecords,
                pageSize: billingPageSize,
                onChange: (page) => {
                  setBillingPage(page);
                },
                hideOnSinglePage: true,
                showSizeChanger: false,
              }}
              loading={{
                spinning: isBillingFetching,
                indicator: (
                  <div>
                    <Spinner style={{ width: "25px" }} />
                  </div>
                ),
              }}
            >
              <Column
                title="Invoice"
                dataIndex="created_at"
                key="created_at"
                render={(_, record: any) => (
                  <>
                    {new Date(record.created_at * 1000).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                      }
                    )}
                  </>
                )}
                sorter={(a: any, b: any) => {
                  const aDate = new Date(a.created_at * 1000);
                  const bDate = new Date(b.created_at * 1000);

                  return aDate.getTime() - bDate.getTime();
                }}
                showSorterTooltip={false}
              />
              <Column
                title="Status"
                dataIndex="subscription_status"
                key="subscription_status"
                render={(_, record: any) => (
                  <div style={{ display: "flex" }}>
                    <StatusBadge
                      $paid={record.subscription_status === "active"}
                    >
                      {record.subscription_status === "active" && (
                        <>
                          <Check size={12} weight="bold" />
                          Paid
                        </>
                      )}
                      {record.subscription_status === "cancel" ||
                        (record.subscription_status === "past_due" && (
                          <>
                            <X size={12} weight="bold" />
                            Unpaid
                          </>
                        ))}
                    </StatusBadge>
                  </div>
                )}
              />
              <Column
                title="Amount"
                dataIndex="amount"
                key="amount"
                render={(_, record: any) => <>${record.amount}</>}
              />
              <Column
                title="Plan"
                dataIndex="plan"
                key="plan"
                render={(_, record: any) => (
                  <>{formatPlan(record.variant_name)} Plan</>
                )}
                responsive={["md"]}
              />
            </Table>
            {billingStatus === "error" && (
              <p style={{ color: "#B42318", marginTop: "30px" }}>
                Failed to fetch billing history. Please try again.
              </p>
            )}
          </InvoiceSection>
        </DevContent>
      </ContentLayout>
      <UpdatePaymentMethodModal
        open={showUpdatePaymentMethodModal}
        setOpen={setShowUpdatePaymentMethodModal}
      />
    </Layout>
  );
}
