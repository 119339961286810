export const TRACKING_ID = "G-4061KC8JMW";
export const DOCUMENTATION_URL = "https://docs.askyourpdf.com/askyourpdf-docs";


type EnvironmentConfig = {
    AUTH_SERVER_URL?: string;
    CONVERSATIONS_SERVER_URL?: string;
    DOCUMENT_MANAGEMENT_SERVER_URL?: string;
    PRO_PLUGIN_SERVER_URL?: string;
    API_SERVICE_SERVER_URL?: string;
    AUTH_FRONTEND_URL?: string;
    CURRENT_ENV: string;
};
function getURL() {
    const environments: Record<string, EnvironmentConfig> = {
        "production": {
            AUTH_SERVER_URL: "https://auth-service.askyourpdf.com",
            CONVERSATIONS_SERVER_URL: "https://chat-api.askyourpdf.com",
            DOCUMENT_MANAGEMENT_SERVER_URL: "https://doc-api.askyourpdf.com",
            PRO_PLUGIN_SERVER_URL: "https://pro.askyourpdf.com",
            API_SERVICE_SERVER_URL: "https://api.askyourpdf.com",
            AUTH_FRONTEND_URL: "https://auth.askyourpdf.com",
            CURRENT_ENV: "production"
        },
        "staging": {
            AUTH_SERVER_URL: "https://auth.askingstage.com",
            CONVERSATIONS_SERVER_URL: "https://chat.askingstage.com",
            DOCUMENT_MANAGEMENT_SERVER_URL: "https://upload.askingstage.com",
            PRO_PLUGIN_SERVER_URL: "https://pro-plugin.askingstage.com",
            API_SERVICE_SERVER_URL: "https://api.askingstage.com",
            AUTH_FRONTEND_URL: "https://beta-auth.askingstage.com",
            CURRENT_ENV: "staging"
        },
        "development": {
            AUTH_SERVER_URL: "http://127.0.0.1:5100",
            CONVERSATIONS_SERVER_URL: "http://127.0.0.1:5200",
            DOCUMENT_MANAGEMENT_SERVER_URL: "http://127.0.0.1:5300",
            PRO_PLUGIN_SERVER_URL: "http://127.0.0.1:5400",
            API_SERVICE_SERVER_URL: "http://127.0.0.1:5500",
            AUTH_FRONTEND_URL: "http://localhost:3000",
            CURRENT_ENV: "development"

        }
    };
    const env = process.env.REACT_APP_MY_ENV || "production";
    return environments[env];
}

export const {
    AUTH_SERVER_URL,
    CONVERSATIONS_SERVER_URL,
    DOCUMENT_MANAGEMENT_SERVER_URL,
    PRO_PLUGIN_SERVER_URL,
    API_SERVICE_SERVER_URL,
    AUTH_FRONTEND_URL,
    CURRENT_ENV
} = getURL();


export const INCLUDE_CREDENTIALS = CURRENT_ENV === "production" ? "include" : undefined;

export enum UserPlan {
    ANONYMOUS = "anonymous",
    BASIC = "basic",
    PREMIUM = "premium",
    PRO = "pro",
    ENTERPRISE = "enterprise"
}

export enum UserAPIPlan {
    API_FREE = "api_free",
    API_PREMIUM = "api_premium",
    API_ENTERPRISE = "api_enterprise"
}

export const UNPAID_ROLES = [UserPlan.ANONYMOUS, UserPlan.BASIC];
export const PAID_ROLES = [UserPlan.PREMIUM]
export const PRO_ROLE = UserPlan.PRO
export const ENTERPRISE_ROLE = UserPlan.ENTERPRISE

export const ANON_CONVERSATION_LIMIT = 1;
export const UNPAID_CONVERSATION_LIMIT = 2;
export const PREMIUM_CONVERSATION_LIMIT = 1000;
export const PRO_CONVERSATION_LIMIT = 1000;
export const ENTERPRISE_CONVERSATION_LIMIT = 10000;

export const API_UNPAID_ROLE = UserAPIPlan.API_FREE;
export const API_PAID_ROLE = UserAPIPlan.API_PREMIUM;
export const API_ENTERPRISE_ROLE = UserAPIPlan.API_ENTERPRISE;

export const WEB_SUBSCRIPTION_PLAN_TYPE = "WEB";
export const API_SUBSCRIPTION_PLAN_TYPE = "API";

export const DEV_PLAN_CONFIG = {
    api_free: {name: "API Free", price: "0", messages: 100, pages: 5000, maximumDocuments : 10},
    api_premium: {name: "API Premium", price: "19.99", messages: 2000, pages: 30000, maximumDocuments : 100},
    api_enterprise: {
        name: "API Enterprise",
        price: "Custom",
        messages: 2000,
        pages: 60000,
    },
};

interface Plans {
    name: string;
    maxFileSizePerDoc: number;
    maxNumberPagesPerDoc: string;
    maxNumberDocsPerDay: string;
    maxNumberConversationsPerDay: string;
    maxNumberQuestionsPerDay: string;
    MaxNumberQuestionsPerDayPlugin: string;
}

export const WEB_PLAN_PRICES: { [key: string]: { [key: string]: string } } = {
    month: {
        free: "0.00",
        premium: "4.99",
        pro: "12.99",
        enterprise: "Custom",
    },
    year: {
        free: "0.00",
        premium: "49.99",
        pro: "99.99",
        enterprise: "Custom",
    },
};

export const WEB_PLAN_CONFIG: Plans[] = [
    {
        name: "Free",
        maxFileSizePerDoc: 15728640,
        maxNumberPagesPerDoc: "100",
        maxNumberDocsPerDay: "1",
        maxNumberQuestionsPerDay: "50",
        MaxNumberQuestionsPerDayPlugin: "50",
        maxNumberConversationsPerDay: "3"
    },
    {
        name: "Premium",
        maxFileSizePerDoc: 31457280,
        maxNumberPagesPerDoc: "2500",
        maxNumberDocsPerDay: "50",
        maxNumberQuestionsPerDay: "1200",
        MaxNumberQuestionsPerDayPlugin: "1200",
        maxNumberConversationsPerDay: "50"
    },
    {
        name: "Pro",
        maxFileSizePerDoc: 94371840,
        maxNumberPagesPerDoc: "6000",
        maxNumberDocsPerDay: "150",
        maxNumberQuestionsPerDay: "Unlimited",
        MaxNumberQuestionsPerDayPlugin: "Unlimited",
        maxNumberConversationsPerDay: "Unlimited"
    },
    {
        name: "Enterprise",
        maxFileSizePerDoc: 150000000,
        maxNumberPagesPerDoc: "2500",
        maxNumberDocsPerDay: "1000",
        maxNumberQuestionsPerDay: "Unlimited",
        MaxNumberQuestionsPerDayPlugin: "Unlimited",
        maxNumberConversationsPerDay: "Unlimited"
    },
];
