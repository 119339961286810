export const faqArrays: { id: number; Question: string; answer: string }[][] = [
  [
    {
      id: 1,
      Question: "What is this application about?",
      answer: " This application lets you chat with any document you upload.",
    },
    {
      id: 2,
      Question: "How does it work?",
      answer:
        "The application uses artificial intelligence to analyze the content of the document and provide a chat interface for users to ask questions get answers.",
    },
    {
      id: 3,
      Question: "Can I upload any document?",
      answer:
        "We support multiple document formats ('.pdf', '.txt', '.ppt', '.pptx', '.csv','.epub', '.rtf')",
    },
    {
      id: 4,
      Question: "Is it necessary to create an account to use the application?",
      answer:
        "No, you do not need to create an account to use the application, but there are restrictions.",
    },
    {
      id: 5,
      Question: "Can I chat with other users of the application?",
      answer:
        "No, the chat interface is only for interacting with the content of the document",
    },
    {
      id: 6,
      Question: "Is there a limit to the number of documents I can upload?",
      answer:
        "No, there is no limit to the number of documents you can upload. However, for now, the application only accepts one document upload per time.",
    },
    {
      id: 7,
      Question: "Is the application free to use?",
      answer: "Yes, the application is free to use.",
    },
    {
      id: 8,
      Question: "Can I chat in any language?",
      answer:
        "Yes, AskYourPDF is multilingual and lets you chat in any language of your choice.",
    },
    {
      id: 9,
      Question: "Can I download the chat history?",
      answer: "Yes, you can download the chat history.",
    },
  ],
  [
    {
      id: 10,
      Question: "What if I have a question that the AI can't answer?",
      answer:
        "If the answer lies in the uploaded document, then there’s high likelihood the AI will answer it correctly.Note that the AI is constantly learning, so if it cannot answer your question,you can always ask a different question.",
    },
    {
      id: 11,
      Question: "Is my data secure?",
      answer:
        "Yes, your data is secure and the application uses industry-standard (SSL encryption) security measures to protect your information.",
    },
    {
      id: 12,
      Question: "Can I edit the document while using AskYourPDF?",
      answer: "No, you cannot edit the document itself while using AskYourPDF.",
    },
    {
      id: 13,
      Question: "Can I use AskYourPDF on any device?",
      answer:
        "Yes, AskYourPDF is accessible from any device with an internet connection.",
    },
    {
      id: 14,
      Question:
        "Is there a limit to the size of the document that can be uploaded?",
      answer:
        "Yes, the maximum limit of the document that can be uploaded is 20mb.",
    },
    {
      id: 15,
      Question: "Can I upload multiple documents at once?",
      answer: "No, you can only upload one document at a time.",
    },
    {
      id: 16,
      Question: "Can I upload password-protected documents to AskYourPDF",
      answer:
        "No, you cannot upload password-protected documents to AskYourPDF.",
    },
    {
      id: 17,
      Question: "How long are the chat sessions saved on AskYourPDF?",
      answer: "The chat session is available as long as the tab is open.",
    },
    {
      id: 18,
      Question:
        "Does AskYourPDF have any limitations on the number of documents that can be uploaded per day?",
      answer:
        "No, there are no limitations on the number of documents that can be uploaded per day",
    },
    {
      id: 19,
      Question:
        "What if I have a question or a problem while using AskYourPDF?",
      answer:
        ">If you have any questions or problems while using AskYourPDF, you can contact our support team, who will be happy to help you.",
    },
  ],
];
