import React, {Fragment, useState} from 'react';
import {Col} from "antd";
import {MagnifyingGlass, MinusCircle, PlusCircle, Question} from '@phosphor-icons/react';
import Hero from "../img/Hero.svg"
import {PageContainer} from "../styles/styles";
import {FaqHeaderContainer, SearchBox, UploadRow, UploadSection, CollapseStyle, PanelStyle} from '../styles/faq'
import '../styles/App.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Waitlist from '../components/Waitlist';
import {faqArrays} from '../utils/faqData';
import styled from "styled-components";


export const SVGContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7.5px;
  height: 100%;
  background: rgba(255, 255, 255, 0.06);
`;
// eslint-disable-next-line @typescript-eslint/no-redeclare
interface Question {
    id: number;
    Question: string;
    answer: string;
}
function FAQ() {
    const [inputValue, setInputValue] = useState('');
    const [filteredSections, setFilteredSections] = useState<Question[][]>(faqArrays);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        const filtered = faqArrays.map((section) =>
        section.filter((item) =>item.Question.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredSections(filtered);
    };
    return (
        <Fragment>
            <PageContainer>
                <Navbar/>
                <FaqHeaderContainer imgUrl={Hero}>
                    <h1>Frequently Asked Questions</h1>
                    <p>Need help? Look through our FAQs for quick answers!</p>
                    <SearchBox>
                        <SVGContainer>
                            <MagnifyingGlass color='#D0D5DD' weight="bold" size={20}/>
                        </SVGContainer>
                        <input type="text" placeholder='Search'  value={inputValue}
                            onChange={handleInputChange}/>
                    </SearchBox>
                </FaqHeaderContainer>
                <UploadSection>
                    <UploadRow justify={"center"} gutter={10}>
                    {filteredSections.map((section, index) => (
                        <Col lg={9} xs={22} key={index}>
                            <CollapseStyle
                            bordered={false}
                            defaultActiveKey={['1']}
                            expandIconPosition="end"
                            expandIcon={({ isActive }: any) =>
                                isActive ? (
                                <MinusCircle size={20} color="#98A2B3" />
                                ) : (
                                <PlusCircle size={20} color="#98A2B3" />
                                )
                            }
                            >
                            {section.map((item) => (
                                <PanelStyle header={item.Question} key={item.id.toString()}>
                                <p>{item.answer}</p>
                                </PanelStyle>
                            ))}
                            </CollapseStyle>
                        </Col>
                    ))}
                    </UploadRow>
                </UploadSection>
                <Waitlist/>
                <Footer/>
            </PageContainer>
        </Fragment>
    )
}

export default FAQ

