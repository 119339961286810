import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  LandingSection,
  LandingFlexCol,
  SectionHead,
} from "../../styles/landing";
import { Container, CTA } from "../../styles/styles";
import FeatureBannerImage from "../../img/FeatureBannerImage.png";
import FeatureBannerImageWebP from "../../img/FeatureBannerImage.webp";
import FeatureBannerImageMobile from "../../img/FeatureBannerImageMobile.png";
import FeatureBannerImageMobileWebP from "../../img/FeatureBannerImageMobile.webp";
import ChatIcon from "../../img/ChatIcon.svg";
import ChevronRight from "../../img/ChevronRight.svg";
import BulbIcon from "../../img/Bulb.png";
import ClockIcon from "../../img/Clock.png";
import LearnIcon from "../../img/Learn.png";
import { motion, useAnimation, useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";

const FeatureBanner = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-top: 30px;
  background: #f8f9ff;
  border-radius: 32px;
  flex-direction: column;
  padding-inline: 24px;
  padding-top: 24px;

  .banner__image {
    img {
      max-width: 100%;
    }
    order: 1;
  }

  @media (min-width: 576px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    padding-inline: 48px;
    padding-top: 48px;
    flex-direction: row;
    .banner__image {
      order: 0;
    }
  }
`;

const BannerText = styled.div`
  margin-bottom: 26px;
  .banner__head {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  h2 {
    margin: 0;
    font-family: "Eudoxus Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -0.016em;
    color: #2f2b43;
  }

  p {
    margin: 0;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: rgba(47, 43, 67, 0.6);
  }

  p:nth-of-type(2) {
    margin-block: 24px;
  }

  @media (min-width: 576px) {
    h2 {
      font-size: 36px;
      line-height: 46px;
    }
  }
`;

const FeatureCards = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-top: 24px;

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Card = styled(motion.div)`
  padding: 24px;
  border: 1px solid rgba(47, 43, 67, 0.1);
  border-radius: 32px;

  img {
    max-width: 48px;
  }

  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;

    letter-spacing: -0.016em;

    color: #2f2b43;
  }

  p {
    margin-top: 0;
    margin-bottom: 24px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: rgba(47, 43, 67, 0.6);
  }

  @media (min-width: 576px) {
    padding: 48px;

    img {
      max-width: unset;
    }

    h2 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;

// million-ignore
export default function Features() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const control = useAnimation();

  const textVariant = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { when: "beforeChildren", staggerChildren: 0.3 },
    },
    hidden: { opacity: 0, y: 30 },
  };

  const featureVariant = {
    visible: { opacity: 1, x: 0, transition: { delay: 0.3, ease: "easeIn" } },
    hidden: { opacity: 0, x: -100 },
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isInView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, isInView]);

  return (
    <LandingSection ref={ref}>
      <Container>
        <LandingFlexCol>
          <SectionHead
            variants={textVariant}
            initial="hidden"
            animate={control}
          >
            <motion.h1 variants={textVariant}>
              Supercharge Document <br /> <span>Interactions</span>
            </motion.h1>
            <motion.p variants={textVariant}>
              Ever wished your documents could talk? With AskYourPDF, they can!
              Powered by ChatGPT we breathe life into your documents, making
              them interactive and engaging. No more endless scrolling or
              skimming – just upload, ask and uncover insights directly from
              your document
            </motion.p>
          </SectionHead>
          <FeatureBanner
            variants={featureVariant}
            initial="hidden"
            animate={control}
          >
            <div className="banner__image">
              <picture>
                <source
                  type="image/webp"
                  srcSet={`${FeatureBannerImageMobileWebP} 1332w, ${FeatureBannerImageWebP} 2664w`}
                  sizes="(min-width: 576px) 2664px, 1332px"
                />
                <source
                  type="image/webp"
                  srcSet={`${FeatureBannerImageMobile} 1332w, ${FeatureBannerImage} 2664w`}
                  sizes="(min-width: 576px) 2664px, 1332px"
                />
                <img
                  src={FeatureBannerImage}
                  srcSet={`${FeatureBannerImageMobile} 1332w, ${FeatureBannerImage} 2664w`}
                  sizes="(min-width: 576px) 2664px, 1332px"
                  alt=""
                />
              </picture>
            </div>
            <BannerText>
              <img src={ChatIcon} alt="" />
              <div className="banner__head">
                <h2>Converse, Learn, Track -</h2>
                <h2>All on Your Terms</h2>
              </div>
              <div className="banner__body">
                <p>
                  Discover a brand new way to interact with your documents on
                  AskYourPDF.
                </p>
                <p>
                  Here, you give voice to your documents, turning them into
                  engaging chat partners. What's more? You can effortlessly
                  track and revisit all your conversations. Take control, ask
                  questions, get answers, and never lose track of your learning.
                  Ready to make your documents work for you?
                </p>
                <CTA
                  onClick={() => {
                    navigate("/conversations");
                  }}
                  style={{ background: "#000000" }}
                >
                  Get Started <img src={ChevronRight} alt="" />
                </CTA>
              </div>
            </BannerText>
          </FeatureBanner>
          <FeatureCards
            variants={textVariant}
            initial="hidden"
            animate={control}
          >
            <Card variants={textVariant}>
              <img src={BulbIcon} alt="" />
              <h2>Insightful Conversations</h2>
              <div className="card__body">
                <p>
                  Unleash the chat power in your documents! Turn them into
                  friendly Chatbot's,ready to share knowledge and insights.
                </p>
                <p>
                  Just upload and voila, your documents aren't just smart,
                  they're your new best friends. Dive in, engage, and enjoy your
                  most interactive reading experience yet!
                </p>
              </div>
            </Card>
            <Card variants={textVariant}>
              <img src={ClockIcon} alt="" />
              <h2>Save Time</h2>
              <div className="card__body">
                <p>
                  AskYourPDF brings the magic of AI to your fingertips! Dive
                  into smart navigation, no more endless scrolling or page
                  skimming.
                </p>
                <p>
                  Your documents transform into interactive buddies, ready to
                  help you understand and engage in an instant!
                </p>
              </div>
            </Card>
            <Card variants={textVariant}>
              <img src={LearnIcon} alt="" />
              <h2>Learn Playfully</h2>
              <div className="card__body">
                <p>
                  Our AI-powered chat system not only provides you with accurate
                  information but also fosters an enjoyable learning environment
                  that keeps you coming back for more.
                </p>
              </div>
            </Card>
          </FeatureCards>
        </LandingFlexCol>
      </Container>
    </LandingSection>
  );
}
