import React from "react";
import styled from "styled-components";
import Sider from "antd/es/layout/Sider";
import { NavLink } from "react-router-dom";
import { ReactComponent as CodeIcon } from "../img/CodeIcon.svg";
import { ReactComponent as CardIcon } from "../img/CardIcon.svg";

const DeveloperSidebarStyles = styled(Sider)`
  display: none;

  @media (min-width: 992px) {
    display: block;
    margin-left: 72px;
    color: #fff;
    padding-top: 85px;
    padding-inline: 20px;
  }
`;

const SidebarContent = styled.div`
  h6 {
    color: #fff;
    font-family: Satoshi, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;

    li {
      padding-block: 24px;
      &:not(:last-child) {
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

const MenuButton = styled(NavLink)`
  display: flex;
  gap: 11px;

  div {
    p {
      color: #ffffff;
      font-family: Satoshi, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    p:first-of-type {
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  svg {
    flex-shrink: 0;

    &#keys_icon {
      path {
        fill: #ffffff;
      }
    }

    &#billing_icon {
      path {
        stroke: #ffffff;
      }
    }
  }

  &.active,
  &:hover {
    div {
      p:first-of-type {
        color: #edb01a;
      }
    }
    svg {
      &#keys_icon {
        path {
          fill: #edb01a;
        }
      }

      &#billing_icon {
        path {
          stroke: #edb01a;
        }
      }
    }
  }
`;

// million-ignore
export default function DeveloperSidebar() {
  return (
    <DeveloperSidebarStyles
      style={{
        background: "#141414",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        overflow: "auto",
        zIndex: 1010
      }}
      width={290}
    >
      <SidebarContent>
        <h6>Developers</h6>
        <ul>
          <li>
            <MenuButton to={"/apikeys"}>
              <CodeIcon id="keys_icon" />
              <div>
                <p>API Keys</p>
                <p>Manage & Create API Keys to connect your applications</p>
              </div>
            </MenuButton>
          </li>
          <li>
            <MenuButton to={"/billing"}>
              <CardIcon id="billing_icon" />
              <div>
                <p>Billing</p>
                <p>Manage payments for your developer account</p>
              </div>
            </MenuButton>
          </li>
        </ul>
      </SidebarContent>
    </DeveloperSidebarStyles>
  );
}
