import React, {useState} from "react";
import styled from "styled-components";
import {Input, Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import {Container} from "../styles/styles";
import DocumentUpload from "../components/DocumentUpload";
import {ReactComponent as CopyIcon} from "../img/CopyIcon.svg";
import AppSidebar from "../components/AppSidebar";
import MobileHeader from "../components/MobileHeader";
import CopyToClipboard from "react-copy-to-clipboard";
import {ActivityLabels, trackButtonClick} from "../utils/analytics";
import { alerts } from "../utils/alerts";

const ContentStyles = styled(Content)`
  background: #f8f8f8;
`;

const ContentContainer = styled(Container)`
  padding-top: 10rem;
`;

const UploadContainer = styled.div`
  border-radius: 16px;

  @media (min-width: 768px) {
    background: #ffffff;
  }
`;

const UploadHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 27px;
  padding-inline: 23px;

  h1,
  p {
    color: #141718;

    font-family: Satoshi, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1.6px;
  }

  p {
    color: #141414;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.28px;

    span {
      font-weight: 700;
    }
  }

  @media (min-width: 768px) {
    padding-top: 52px;
    padding-bottom: 27px;
    padding-inline: 65px;

    border-bottom: solid 1px #e4e7ec;
    flex-direction: row;
    gap: unset;

    p {
      max-width: 370px;
    }
  }
`;

const UploadBody = styled.div`
  padding-inline: 23px;

  @media (min-width: 768px) {
    padding-block: 70px;
    padding-inline: 80px;
  }
`;

const DocumentID = styled.div`
  margin-top: 23px;
  margin-bottom: 18px;

  p {
    margin-bottom: 6px;
    color: #344054;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .ant-input-group {
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .ant-input-group-addon {
    background: #ffffff;
    color: #667085;
    border-radius: 8px;
    padding: 10px 12px 10px 14px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  .ant-input-affix-wrapper {
    border-radius: 8px;
    padding: 10px 14px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    .ant-input {
      color: #667085;
      font-size: 16px;
      font-family: Satoshi, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .ant-input-affix-wrapper-disabled {
    background: unset;
    cursor: unset;
    color: unset;

    .ant-input-disabled {
      box-shadow: unset;
      cursor: default;
    }
  }

  @media (min-width: 768px) {
    margin-top: 60px;
  }
`;

const IDField = styled(Input)`
  margin-bottom: 11px;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  svg {
    display: block;

    path {
      stroke: #292d32;
    }
  }
`;

const UploadAlert = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #6ce9a6;
  background: #f6fef9;

  h2,
  p {
    color: #027a48;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  p {
    color: #039855;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const ErrorAlert = styled(UploadAlert)`
  margin-top: 23px;
  border: 1px solid rgb(253, 162, 155);
  background: rgb(255, 251, 250);

  h2,
  p {
    color: rgb(180, 35, 24);
  }

  @media (min-width: 768px) {
    margin-top: 60px;
  }
`;

const DocumentLayout = styled(Layout)`
  @media (min-width: 992px) {
    margin-left: 72px;
  }
`;

export default function GenerateID() {
    const [documentID, setDocumentID] = useState("");
    const [uploadError, setUploadError] = useState("");

    return (
        <Layout hasSider style={{minHeight: "100vh"}}>
            <AppSidebar/>
            <DocumentLayout>
                <MobileHeader/>
                <ContentStyles>
                    <ContentContainer>
                        <UploadContainer>
                            <UploadHeader>
                                <h1>Upload document</h1>
                                <p>
                                    <span>We'll assign an ID to your document.</span> You'll be
                                    able to start a conversation in ChatGPT based on the document
                                    uploaded. You can upgrade your account to increase your
                                    limits.
                                </p>
                            </UploadHeader>
                            <UploadBody>
                                <DocumentUpload
                                    onUpload={(id) => {
                                        trackButtonClick(ActivityLabels.PLUGIN_UPLOAD_SUCCESS);
                                        setDocumentID(id);
                                    }}
                                    onError={(error) => {
                                        trackButtonClick(ActivityLabels.PLUGIN_UPLOAD_FAILURE);
                                        setDocumentID("");
                                        if (error?.detail) {
                                            setUploadError(error?.detail);
                                        } else if (error?.message) {
                                            if (error?.message.includes("detail")) {
                                                setUploadError(JSON.parse(error.message).detail);
                                            }
                                        }
                                    }}
                                    onRemoved={() => {
                                        setDocumentID("");
                                        setUploadError("");
                                    }}
                                    allowUploadModeSwitch={false}
                                    defaultUploadMode={0}
                                />
                                {(documentID !== "" || uploadError) &&
                                    (uploadError ? (
                                        <ErrorAlert>
                                            <h2>File upload failed</h2>
                                            <p>{uploadError}</p>
                                        </ErrorAlert>
                                    ) : (
                                        <>
                                            <DocumentID>
                                                <p>Document Id</p>
                                                <IDField
                                                    disabled
                                                    suffix={
                                                        <CopyToClipboard
                                                            text={documentID}
                                                            onCopy={() => {
                                                                alerts.success("Copied", "ID Copied");
                                                            }}
                                                        >
                                                            <CopyButton>
                                                                <CopyIcon/>
                                                            </CopyButton>
                                                        </CopyToClipboard>
                                                    }
                                                    value={documentID}
                                                />
                                            </DocumentID>
                                            <UploadAlert>
                                                <h2>Doc ID Successfully Generated</h2>
                                                <p>
                                                    Please copy the document ID above and paste it into
                                                    the{" "}
                                                    <a
                                                        href="https://chat.openai.com/?model=gpt-4-plugins"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        ChatGPT UI
                                                    </a>{" "}
                                                    to have a conversation about this document.
                                                </p>
                                            </UploadAlert>
                                        </>
                                    ))}
                            </UploadBody>
                        </UploadContainer>
                    </ContentContainer>
                </ContentStyles>
            </DocumentLayout>
        </Layout>
    );
}
