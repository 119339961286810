import React, {useEffect, useState} from "react";
import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {Container} from "../styles/styles";
import DocumentUpload from "../components/DocumentUpload";
import {X} from "@phosphor-icons/react";
import AppSidebar from "../components/AppSidebar";
import {useAuth} from "../providers/AuthProvider";
import AppHeader from "../components/AppHeader";
import {PAID_ROLES, UNPAID_ROLES} from "../config/config";
import AppAlert from "../components/AppAlert";
import {useConversations} from "../providers/ConversationsProvider";
import {getUserRole, validateConversationCount} from "../utils/utils";
import PaymentModal from "../components/Modals/PaymentModal";

const MainContainer = styled(Container)`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;

  @media (min-width: 576px) {
    max-width: unset;
  }

  @media (min-width: 768px) {
    max-width: unset;
  }

  @media (min-width: 992px) {
    max-width: 992px;
    height: unset;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

const AppContainer = styled.div`
  background: #ffffff;
  width: 100%;
  margin-inline: auto;

  @media (min-width: 992px) {
    border-radius: 16px;
    min-height: 640px;
  }
`;

const IntroSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    margin: 0;

    color: #141718;
    font-size: 40px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1.6px;
  }

  p {
    margin: 0;
    color: #141414;
    font-size: 14px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.28px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    gap: unset;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: solid 1px #e4e7ec;
    padding-block: 30px;
    padding-inline: 80px;

    h1 {
      max-width: 280px;
    }

    p {
      max-width: 368px;
    }
  }
`;

const UploadSection = styled.section`
  h2,
  p {
    margin: 0;
  }

  h2 {
    color: #141718;
    font-size: 20px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.8px;
  }

  p {
    color: #8a91a8;
    font-size: 14px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.28px;
  }

  @media (min-width: 992px) {
    padding-top: 26px;
    padding-inline: 70px;
    display: block;
  }
`;

const UploadAction = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  gap: 20px;

  p {
    color: #141414;
    font-size: 14px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.28px;
  }

  button {
    padding: 16px 24px;
    border-radius: 12px;
    border: none;
    background: #000000;
    color: #ffffff;
    font-size: 16px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.32px;
    cursor: pointer;

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: unset;

    p {
      max-width: 304px;
    }
  }

  @media (min-width: 992px) {
    margin-top: 60px;
  }
`;

const AppLayout = styled(Layout)`
  .upgrade__alert {
    display: none;
  }

  .ant-layout-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #ffffff;
    padding-block: 1rem;
  }

  @media (min-width: 992px) {
    margin-left: 72px;

    .ant-layout-content {
      padding-block: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      padding-block: 4rem;
    }

    .upgrade__alert {
      display: block;
    }
  }
`;

const MobileActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 64px;

  button {
    margin-top: 1rem;
    background: none;
    border-radius: 8px;
    border: 1px solid #000;
    padding: 7px 24px;

    color: #141414;
    font-size: 14px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.28px;
    cursor: pointer;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

export default function Upload() {
    const navigate = useNavigate();
    const {userDetails} = useAuth();
    const [documentID, setDocumentID] = useState("");
    const [uploadError, setUploadError] = useState("");
    const {conversationCount} = useConversations();
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const {userAnalytics} = useAuth();

    const onUpload = (id: string) => {
        setDocumentID(id);
    };

    useEffect(() => {
        if (!validateConversationCount(userDetails, conversationCount, userAnalytics ? userAnalytics.number_of_conversations : -1)) {
            navigate("/conversations");
        }
    }, [conversationCount, userDetails, userAnalytics]);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (uploadError) {
            timer = setTimeout(() => {
                setUploadError("");
            }, 10000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [uploadError]);


    useEffect(() => {
        if (documentID) {
            navigate(`/conversations`, {
                state: {docID: documentID},
            });
        }
    }, [documentID, navigate]);

    const userRole = getUserRole(userDetails);

    return (
        <Layout hasSider style={{minHeight: "100vh"}}>
            <AppSidebar/>
            <AppLayout>
                <AppHeader/>
                {uploadError ? (
                    <div>
                        {uploadError && <AppAlert type="error" message={uploadError}/>}
                    </div>
                ) : (
                    <div className="upgrade__alert">
                        {userDetails?.email !== undefined &&
                            userRole === "free" && (
                                <AppAlert
                                    type="info"
                                    message="Upgrade to access more features"
                                />
                            )}
                    </div>
                )}

                <Content>
                    <MainContainer style={{width: "100%"}}>
                        <MobileActions>
                            <Link to={"/conversations"}>
                                <X size={32} color={"#6C7275"}/>
                            </Link>
                            {userDetails?.email !== undefined &&
                                userRole === "free" && (
                                    <button
                                        onClick={() => {
                                            setShowPaymentModal(true);
                                        }}
                                    >
                                        Upgrade
                                    </button>
                                )}
                        </MobileActions>
                        <AppContainer>
                            <IntroSection>
                                <h1>Upload your document</h1>
                                <p>
                                    You'll be able to start a conversation based on the document
                                    uploaded. You can upgrade your account to increase your limits
                                </p>
                            </IntroSection>
                            <UploadSection>
                                <DocumentUpload
                                    onUpload={onUpload}
                                    onError={(error) => {
                                        setDocumentID("");
                                        if (error?.detail) {
                                            setUploadError(error?.detail);
                                        } else if (error?.message) {
                                            if (error?.message.includes("detail")) {
                                                setUploadError(JSON.parse(error.message).detail);
                                            }
                                        }
                                    }}
                                    onRemoved={() => {
                                        setDocumentID("");
                                        setUploadError("");
                                    }}
                                />
                                <UploadAction>
                                    <p>
                                        By starting a conversation you agree with our data privacy
                                        policy and terms
                                    </p>
                                    <button
                                        onClick={() => {
                                            navigate(`/conversations`, {
                                                state: {docID: documentID},
                                            });
                                        }}
                                        disabled={documentID === ""}
                                    >
                                        Start Conversation
                                    </button>
                                </UploadAction>
                            </UploadSection>
                        </AppContainer>
                    </MainContainer>
                </Content>
                <PaymentModal open={showPaymentModal} setOpen={setShowPaymentModal}/>
            </AppLayout>
        </Layout>
    );
}
