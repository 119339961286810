import React, {ReactNode, createContext, useEffect, useState} from "react";
import {useLocalStorage} from "react-use";
import {createAnonymousUser, currentUser} from "../services/authService";
import {useQuery} from "react-query";
import {Space, Spin} from "antd";
import {Loading} from "../styles/styles";
import Spinner from "../components/Spinner";
import {getDailyUsage} from "../services/documents";
import getBrowserFingerprint from "get-browser-fingerprint";
import {CURRENT_ENV} from "../config/config";
import { useLocation } from "react-router-dom";

const AuthContext = createContext<any>({});

export const AuthProvider = ({children}: { children: ReactNode }) => {
    const [userDetails, setUserDetails] = useState({});
    const [userToken, setUserToken] = useState<any>();
    const [auth, setAuth, removeAuth] = useLocalStorage<any>("auth");
    const [anonUser] = useLocalStorage<any>("anonUser");
    const [isLoadingAuth, setIsLoadingAuth] = useState(false);

    const {pathname} = useLocation();

    const {isLoading, isError, data, error} = useQuery(
        "fetchCurrentUser",
        async () => {
            return await currentUser();
        },
        {
            staleTime: 5 * (60 * 1000),
            cacheTime: 10 * (60 * 1000),
        }
    );

    React.useEffect(() => {
        if (!isLoading && !isError && data?.is_active) {
            setAuth({access_token: "true"});
        } else if (isError) {
            removeAuth();
        }
    }, [isLoading, isError, data, setAuth, removeAuth]);

    useEffect(() => {
        (async () => {
            try {
                if (auth?.access_token) {
                    const response = await currentUser(auth.access_token);
                    setUserDetails(response);
                } else {
                    setUserDetails({});
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [auth]);

    useEffect(() => {
        (async () => {
            try {
                setIsLoadingAuth(true);
                if (anonUser?.userId) {
                    setUserToken(CURRENT_ENV === "production" ? anonUser?.userId : localStorage.getItem("token"));
                } else {
                    const fingerprint = getBrowserFingerprint();
                    const parsedFingerprint = String(fingerprint);

                    const response = await createAnonymousUser(parsedFingerprint);
                    localStorage.setItem(
                        "anonUser",
                        JSON.stringify({userId: parsedFingerprint})
                    );
                    setUserToken(parsedFingerprint);
                    setUserDetails({});
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoadingAuth(false);
            }
        })();
    }, [auth, anonUser]);

    const {data: userAnalytics, isLoading: isUserAnalyticsLoading} = useQuery({
        queryKey: "userAnalytics",
        queryFn: async () => getDailyUsage(userToken!),
        enabled: userToken !== undefined,
        refetchOnWindowFocus: false,
        select: (data) => {
            return {
                ...data,
                number_of_conversations: Number(data.number_of_conversations),
            };
        },
    });

    if ((isLoading || isLoadingAuth) && pathname !== "/") {
        return (
            <Loading>
                <Space size="middle">
                    <Spinner/>
                </Space>
            </Loading>
        );
    }

    return (
        <AuthContext.Provider
            value={{
                userDetails,
                userToken,
                auth,
                setAuth,
                removeAuth,
                userAnalytics,
                isUserAnalyticsLoading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}

export default AuthContext;
