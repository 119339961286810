export const TermsArrays: { id: number; Question: string; answer: string }[] = [
  {
    id: 1,
    Question: "Acceptance of Terms",
    answer:
      "  By accessing or using AskYourPDF, you agree to be bound by these terms andconditions. If you do not agree with these terms and conditions, you must not use AskYourPDF..",
  },
  {
    id: 2,
    Question: "Use of AskYourPDF",
    answer:
      "You may use AskYourPDF for personal or non-commercial purposes only. You must not use AskYourPDF for any illegal or unauthorized purpose.",
  },
  {
    id: 3,
    Question: "Uploading documents",
    answer:
      "You may upload documents to AskYourPDF for the purpose of discussing them with other users. You must not upload any documents that are illegal, defamatory, obscene, or otherwise objectionable.",
  },
  {
    id: 4,
    Question: "User Conduct",
    answer:
      "You are solely responsible for your conduct on AskYourPDF. You must not engage in any conduct that is illegal, defamatory, threatening, or abusive. You must not upload any documents that infringe the intellectual property rights of  others.",
  },
  {
    id: 5,
    Question: "User Content",
    answer:
      "AskYourPDF allows you to import and view documents. You retain     full ownership and control over the content you import into the app. We do not claim ownership of your content, nor do we monitor or access it unless required by  applicable laws.",
  },
  {
    id: 6,
    Question: "App Permissions",
    answer:
      "In order to provide the intended functionality of our app, we may request certain permissions from you, such as access to your device's storage or file system. These permissions are solely for the purpose of allowing you to import and view documents stored on your device.",
  },
  {
    id: 7,
    Question: "Automatically Collected Information",
    answer:
      "When you use our app, we may collect certain information automatically, such as technical information about your device, app usage statistics, and crash reports. This information is collected in an anonymized and aggregated form and is used to improve our app's performance, user experience, and overall quality.",
  },
  {
    id: 8,
    Question: "Privacy",
    answer:
      "Your privacy is important to us. Please see our privacy policy for information on how we collect, use, and protect your personal information.",
  },
  {
    id: 9,
    Question: "Termination",
    answer:
      "We may terminate your use of AskYourPDF at any time for any reason, including if we believe that you have violated these terms and conditions.",
  },
  {
    id: 10,
    Question: "Disclaimer of Warranties",
    answer:
      'AskYourPDF is provided on an "as is" and "as available" basis. We do not warrant that AskYourPDF will be uninterrupted, error-free, or free from downtime.',
  },
  {
    id: 11,
    Question: "Limitation of Liability",
    answer:
      "o the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of AskYourPDF.",
  },
  {
    id: 12,
    Question: "Governing Law",
    answer:
      "These terms and conditions shall be governed by and construed in accordance with the laws of the country or state where it is used.",
  },
  {
    id: 13,
    Question: "Changes to these Terms and Conditions",
    answer:
      "We reserve the right to modify these terms and conditions at any time without prior notice. Your continued use of AskYourPDF after any changes to these terms and conditions constitutes your acceptance of the revised terms and conditions.",
  },
];
