import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { ReactComponent as CloseIcon } from "../../img/ModalCloseIcon.svg";
import DocumentUpload from "../DocumentUpload";
import { useNavigate } from "react-router-dom";

type props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }

  .ant-modal-content {
    padding-bottom: 34px;
    padding-inline: 16px;
  }

  @media (min-width: 576px) {
    .ant-modal-content {
      padding-bottom: 34px;
      padding-inline: 30px;
    }
  }
`;

export default function DocumentUploadModal({ open, setOpen }: props) {
  const navigate = useNavigate();

  const onUpload = (id: string) => {
    navigate("/conversations", { state: { docID: id } });
  };

  return (
    <UploadModal
      open={open}
      onOk={() => {
        setOpen(false);
      }}
      onCancel={() => {
        setOpen(false);
      }}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.46)",
        backdropFilter: "blur(5px)",
      }}
      width={700}
      footer={null}
      closeIcon={<CloseIcon />}
    >
      <DocumentUpload onUpload={onUpload} />
    </UploadModal>
  );
}
