import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../img/Logo.svg";
import Sider from "antd/es/layout/Sider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MessageSquare } from "../img/MessageSquare.svg";
import { ReactComponent as HelpCircle } from "../img/HelpCircle.svg";
import { ReactComponent as SettingsIcon } from "../img/Settings.svg";
import { ReactComponent as UserIcon } from "../img/User.svg";
import { ReactComponent as LogoutIcon } from "../img/Logout.svg";
import { Badge, Popover } from "antd";
import { path } from "../routes";
import { CloudArrowUp, CodeSimple } from "@phosphor-icons/react";
import Profile from "./Modals/Profile";
import { useAuth } from "../providers/AuthProvider";
import { useConversations } from "../providers/ConversationsProvider";
import { logoutUser } from "../services/authService";

const AppSidebarContainer = styled(Sider)`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  height: calc(100% - 64px);

  ul {
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 36px;

    li {
      .ant-badge .ant-badge-count {
        color: #344054;
        text-align: center;
        font-size: 12px;
        font-family: Satoshi, sans-serif;
        font-style: normal;
        font-weight: 500;
        box-shadow: none;
      }
    }
  }
`;

const MenuButton = styled(Link)`
  display: flex;
  border-radius: 7.628px;
  padding: 8px;

  &:hover {
    background: #292928;
  }

  &.active {
    background: #292928;
  }
`;
const LogoutButton = styled.div`
  display: flex;
  border-radius: 7.628px;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background: #292928;
  }

  &.active {
    background: #292928;
  }
`;

const NotLinkMenuButton = styled.div`
  display: flex;
  border-radius: 7.628px;
  padding: 8px;

  &:hover {
    background: #292928;
  }

  &.active {
    background: #292928;
  }
`;

export default function AppSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(false);
  const { userDetails, removeAuth } = useAuth();
  const { conversationCount } = useConversations();
  const handleLogout = async () => {
    try {
      if (userDetails) {
        await logoutUser();
        removeAuth();
      }
    } catch (e) {
      console.error(e);
    } finally {
      navigate("/", { replace: true });
    }
  };
  return (
    <AppSidebarContainer
      style={{
        background: "#070707",
        paddingInline: "16px",
        paddingBottom: "32px",
        paddingTop: "20px",
        textAlign: "center",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        overflow: "auto",
        zIndex: 1010,
      }}
      width={72}
    >
      {profile && (
        <Profile
          currentPath={location.pathname}
          profile={profile}
          setProfile={setProfile}
        />
      )}
      <Link to={"/"}>
        <Logo />
      </Link>
      <SidebarContent>
        <ul>
          <li>
            <Badge
              color="#F2F4F7"
              count={conversationCount > -1 ? conversationCount : "-"}
              size="small"
              style={{ marginRight: "6px" }}
            >
              <MenuButton
                to={"/conversations"}
                className={
                  [
                    path.chat,
                    path.conversations,
                    path.upload,
                    path.documents,
                    "/conversations",
                    "/documents",
                    "/chat"
                  ].some((url) => location.pathname.includes(url)) && !profile
                    ? "active"
                    : ""
                }
              >
                <MessageSquare />
              </MenuButton>
            </Badge>
          </li>
          {userDetails?.email && (
            <>
              <li>
                <MenuButton
                  to={path.apiKeys}
                  className={
                    [path.apiKeys, path.billingInfo].some((url) =>
                      location.pathname.includes(url)
                    )
                      ? "active"
                      : ""
                  }
                >
                  <CodeSimple size={24} color="#FFFFFF" />
                </MenuButton>
              </li>
              <li>
                <MenuButton
                  to={path.gptUpload}
                  className={
                    [path.gptUpload].some((url) =>
                      location.pathname.includes(url)
                    )
                      ? "active"
                      : ""
                  }
                >
                  <CloudArrowUp size={24} color="#FFFFFF" />
                </MenuButton>
              </li>
            </>
          )}
        </ul>
        <ul>
          <MenuButton
            to={"https://docs.askyourpdf.com/askyourpdf-docs/"}
            target="_blank"
          >
            <HelpCircle />
          </MenuButton>
          {userDetails?.email && (
            <>
              <MenuButton
                to={path.settings}
                className={
                  location.pathname.includes(path.settings) && !profile
                    ? "active"
                    : ""
                }
              >
                <SettingsIcon />
              </MenuButton>
              <NotLinkMenuButton
                className={profile ? "active" : ""}
                onClick={() => setProfile(!profile)}
              >
                <UserIcon />
              </NotLinkMenuButton>
            </>
          )}

          {userDetails?.email && (
            <Popover placement="right" content="Logout">
              <LogoutButton onClick={handleLogout}>
                <LogoutIcon />
              </LogoutButton>
            </Popover>
          )}
        </ul>
      </SidebarContent>
    </AppSidebarContainer>
  );
}
