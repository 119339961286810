import React from 'react'
import styled from 'styled-components'

const FallbackStyles = styled.div`
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
`;

export default function SuspenseFallback({children}: {children: React.ReactNode}) {
  return (
    <FallbackStyles>{children}</FallbackStyles>
  )
}
