import React from "react";
import AltSpinner from "../img/AltSpinner.gif";
import PrimarySpinner from "../img/PrimarySpinner.gif";

export default function Spinner({
  type = "primary",
  style,
}: {
  type?: "primary" | "alt";
  style?: React.CSSProperties;
}) {
  return type === "primary" ? (
    <img style={style} src={PrimarySpinner} alt="" />
  ) : (
    <img style={style} src={AltSpinner} alt="" />
  );
}
