import React, { Fragment } from "react";
import Hero from "../img/Hero.svg";
import { PageContainer } from "../styles/styles";
import "../styles/App.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Waitlist from "../components/Waitlist";
import { HeaderContainer, TextArea } from "../styles/privacy";
function Privacy() {
  return (
    <Fragment>
      <PageContainer>
        <Navbar />
        <HeaderContainer imgUrl={Hero}>
          <h1>Privacy Policy</h1>
          <p>Respecting Your Privacy: Our Commitment and Assurance</p>
        </HeaderContainer>
        <TextArea>
          <p>
            At AskYourPDF, your privacy isn't just our responsibility – it's the
            foundation of the trust we hope to build with you. We are deeply
            committed to creating a platform where you can interact with your
            documents without compromising on your privacy. This Privacy Policy
            explains our practices in detail, outlining what data we collect,
            how we collect it, why we collect it, and how we ensure that your
            data is handled with the highest level of care.
          </p>

          <h1>The Information We Collect and Its Use</h1>

          <p>
            To provide the best possible experience on AskYourPDF, we collect
            certain types of data from our users:
          </p>

          <p>
            <b>1. Account Information:</b> When you create an account with us,
            we collect basic information such as your name, email address, and
            password. This information is used to identify you and provide
            personalized services.
          </p>

          <p>
            <b>2. Uploaded documents:</b> When you upload a document to our
            platform, we temporarily store the file to provide you with our
            conversational document service. We do not use your documents for
            any other purposes.
          </p>

          <p>
            <b>3. User Interactions:</b> We collect data about how you interact
            with our services. This includes data such as the time, frequency,
            and duration of your interactions. This helps us understand usage
            patterns, improve our services, and provide you with a more
            personalized experience.
          </p>

          <h1>Control Over Your Data</h1>

          <p>
            At AskYourPDF, we value and respect your control over your data. You
            can delete your uploaded document files from our service at any
            time, completely erasing them from our servers. To ensure the
            privacy of our free users, we go one step further by automatically
            deleting uploaded files a few hours after use. This helps us ensure
            that no residual data remains on our servers longer than necessary.
          </p>

          <h1>Security of Your Information</h1>

          <p>
            We understand the sensitivity of your personal information and
            implement strict security measures to safeguard it. We store your
            information on secure servers, and access is strictly limited to a
            small number of authorized personnel who are required to keep the
            information confidential. Furthermore, we review and update our
            security measures on a regular basis.
          </p>

          <h1>Third-Party Services</h1>

          <p>
            Our Privacy Policy does not apply to services offered by other
            companies or individuals, including third-party services used in
            conjunction with AskYourPDF. We recommend that you review the
            privacy policies of any third-party services you may use.
          </p>

          <h1>Changes to the Privacy Policy</h1>

          <p>
            We reserve the right to update or amend this Privacy Policy at any
            time to reflect changes in our practices or services, or changes in
            the law. We will notify you of any significant changes and encourage
            you to review this policy periodically to stay informed.
          </p>

          <p>
            Your continued use of AskYourPDF services after any changes to this
            Privacy Policy signifies your acceptance of our practices as set out
            in the revised policy.{" "}
          </p>

          <h1>Contact Us</h1>

          <p>
            If you have any questions or concerns about our privacy practices or
            this Privacy Policy, please don't hesitate to contact us. We value
            your opinions and look forward to hearing from you.
          </p>

          <p>
            By using AskYourPDF, you acknowledge that you have read, understood,
            and agreed to the practices as described in this Privacy Policy."
          </p>
        </TextArea>
        <Waitlist />
        <Footer />
      </PageContainer>
    </Fragment>
  );
}

export default Privacy;
