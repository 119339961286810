import React from "react";
import Visa from "../img/card_icons/visa.svg";
import MasterCard from "../img/card_icons/mastercard.svg";
import Amex from "../img/card_icons/amex.svg";
import Discover from "../img/card_icons/discover.svg";
import JCB from "../img/card_icons/jcb.svg";
import Diners from "../img/card_icons/diners.svg";
import Unionpay from "../img/card_icons/unionpay.svg";
import Generic from "../img/card_icons/generic.svg";

interface props {
  cardBrand?: string;
  style?: React.CSSProperties;
}

const cardIconMap: { [key: string]: string } = {
  visa: Visa,
  mastercard: MasterCard,
  amex: Amex,
  discover: Discover,
  jcb: JCB,
  diners: Diners,
  unionpay: Unionpay,
};

const defaultCardIcon = Generic;

// million-ignore
export default function CardBrandIcon({ cardBrand, style }: props) {
  const iconSrc = cardIconMap[cardBrand ?? ""] || defaultCardIcon;

  return (
    <img
      src={iconSrc}
      alt={cardBrand}
      style={{ maxWidth: "100%", display: "block", ...style }}
    />
  );
}
