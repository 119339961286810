import React from 'react'
import Hero from "../img/Hero.svg"
import {PageContainer} from "../styles/styles";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Waitlist from '../components/Waitlist';
import {HeaderContainer} from '../styles/privacy';
import {Col} from 'antd';
import {MinusCircle, PlusCircle} from '@phosphor-icons/react';
import {UploadRow, UploadSection, CollapseStyle, PanelStyle} from '../styles/faq';
import {TermsArrays} from '../utils/termsData';
import {Helmet} from "react-helmet";

function Terms() {
    return (
        <div>
            <Helmet>
                <meta name="description"
                      content="AskYourPDF serves as a tool that empowers users to engage with their
                      PDF documents in a human-like manner. Its functionality involves analyzing
                      PDF files to generate a semantic index, which is then utilized to present pertinent
                       paragraphs to a text-generation AI."
                />
            </Helmet>
            <PageContainer>
                <Navbar/>
                <HeaderContainer imgUrl={Hero}>
                    <h1>Terms & Conditions</h1>
                    <p>
                        Welcome to AskYourPDF, Please read these terms and conditions
                        carefully as they govern your use of the product.
                    </p>
                </HeaderContainer>
                <UploadSection>
                    <UploadRow justify={"center"}>
                        <Col lg={20} xs={22}>
                            <CollapseStyle
                                bordered={false}
                                defaultActiveKey={["1"]}
                                expandIconPosition={"end"}
                                expandIcon={({isActive}: any) =>
                                    isActive ? (
                                        <MinusCircle size={20} color="#98A2B3"/>
                                    ) : (
                                        <PlusCircle size={20} color="#98A2B3"/>
                                    )
                                }
                            >
                                {TermsArrays?.map((item) => (
                                    <PanelStyle header={item.Question} key={item.id.toString()}>
                                        <p>{item.answer}</p>
                                    </PanelStyle>
                                ))}
                            </CollapseStyle>
                        </Col>
                    </UploadRow>
                </UploadSection>
                <Waitlist/>
                <Footer/>
            </PageContainer>
        </div>
    );
}

export default Terms
