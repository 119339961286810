import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown, Layout, Slider } from "antd";
import AppSidebar from "../components/AppSidebar";
import DeveloperSidebar from "../components/DeveloperSidebar";
import { Content } from "antd/es/layout/layout";
import {
  ArrowLeft,
  ArrowUpRight,
  CaretDown,
  CheckCircle,
} from "@phosphor-icons/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { AUTH_FRONTEND_URL, DEV_PLAN_CONFIG, DOCUMENTATION_URL, UserAPIPlan } from "../config/config";
import { getAPIRole } from "../utils/utils";
import { useSubscription } from "../providers/SubscriptionProvider";
import Spinner from "../components/Spinner";
import { useMutation } from "react-query";
import {
  ActionType,
  Plan,
  SubType,
  Subscription,
  manageSubscription,
  subscribeToPlan,
} from "../services/payment";
import {
  DeleteModal,
  ModalContentStyles,
} from "../components/Modals/DocumentDeleteModal";
import { alerts } from "../utils/alerts";

const DevContent = styled(Content)`
  padding-inline: 20px;
  padding-top: 15px;
  padding-bottom: 45px;
  background: #ffffff;

  @media (min-width: 576px) {
    padding-inline: 40px;
  }

  @media (min-width: 992px) {
    padding-block: 45px;
    padding-inline: 40px;
  }
  @media (min-width: 1280px) {
    padding-inline: 110px;
  }
`;

const TitleSection = styled.section`
  display: none;

  h1 {
    color: #141718;
    font-family: Satoshi, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1.6px;
  }

  a {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid var(--black, #000);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #000;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const HeaderSection = styled.section`
  padding-bottom: 20px;
  border-bottom: solid 1px #eaecf0;

  h2 {
    color: #101828;
    font-family: Satoshi, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  p {
    color: #475467;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  @media (min-width: 992px) {
    margin-top: 50px;
  }
`;

const PlanActionButton = styled.button`
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid #000;
  background: #000;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: #fff;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const PlanContactUsButton = styled.button`
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid #000;
  background: #000;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  color: #fff;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ContentLayout = styled(Layout)`
  @media (min-width: 992px) {
    margin-left: 362px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background: #050505;
  padding-inline: 30px;
  padding-block: 30px;

  position: sticky;
  top: 0;
  z-index: 100;

  button {
    border: none;
    background: none;
    cursor: pointer;

    svg {
      display: block;
    }
  }

  p {
    color: #fff;
    font-family: Satoshi, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-left: 22px;
  }

  a {
    margin-left: auto;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #ffffff;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

const MobilePageSwitcher = styled.button`
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  width: 100%;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (min-width: 992px) {
    display: none;
  }
`;

const ActivePlanInfo = styled.div`
  div {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const ActivePlanHeader = styled.div``;

const ActivePlanBody = styled.div``;

const PlanBadge = styled.div<{ $color?: string; $background?: string }>`
  border-radius: 16px;
  padding: 2px 10px;
  background: ${(props) => (props.$background ? props.$background : "#F2F4F7")};
  color: ${(props) => (props.$color ? props.$color : "#344054")};
  text-align: center;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const PlanSection = styled.div`
  margin-top: 25px;

  @media (min-width: 992px) {
    margin-top: 60px;
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  font-family: Satoshi, sans-serif;
  font-style: normal;

  h1 {
    color: #101828;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;
  }

  p {
    color: #475467;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const PlanItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 28px;
`;

const PlanItem = styled.div<{ $active?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.$active ? "1fr auto" : "1fr")};
  align-items: flex-start;
  gap: 4px;
  padding: 16px 16px 16px 24px;
  background: ${(props) => (props.$active ? "#f9fafb" : "ffffff")};
  border-radius: 12px;
  border: ${(props) =>
    props.$active ? "2px solid #475467" : "1px solid #D6D9E0"};

  ${PlanBadge} {
    width: fit-content;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr auto;
  }
  a {
    text-decoration: none;
    width: 100%;
  }
`;

const PlanItemInfo = styled.div`
  .ant-slider {
    max-width: 520px;
    margin-top: 0;

    .ant-slider-rail {
      height: 8px;
      border-radius: 4px;
      background: #f3f3f3;
    }

    .ant-slider-track {
      height: 8px;
      border-radius: 4px;
      background: #000;
    }

    .ant-slider-handle {
      transform: translateX(-50%) translateY(-50%) !important;
      inset-inline-start: -1px;
      inset-block-start: -1px;

      &:focus,
      &:hover {
        &::after {
          inset-inline-start: unset;
          inset-block-start: unset;
        }
      }

      &::after {
        width: 26px;
        height: 26px;
        background: #404040;
        box-shadow: unset;
      }
    }
  }

  h1 {
    color: #2c2c2c;
    font-family: Satoshi, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
  }

  .plan__benefits {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
    p {
      color: #141718;
      font-family: Satoshi, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      span {
        font-weight: 400;
      }
    }

    p#enterprise__plan__text {
      margin-top: 8px;
      color: #475467;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.28px;
    }
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(8px);
  z-index: 10;
  height: 100%;
  align-items: center;

  @media (min-width: 992px) {
    margin-left: 180px;
  }
`;

// million-ignore
export default function BillingUpgrade() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails, userToken } = useAuth();

  const [sliderValue, setSliderValue] = useState(3);
  const [confirmDownGrade, setConfirmDownGrade] = useState(false);

  const {
    devSubscriptionInfo: subscriptionInfo,
    subscriptionStatus,
    isSubscriptionError,
    subscriptionError,
  } = useSubscription();

  const is404Error = isSubscriptionError && subscriptionError.status === 404;

  const isSubscriptionCanceled = subscriptionInfo ? subscriptionInfo.subscription_status === "canceled" && new Date().getTime() < new Date(subscriptionInfo.renewal_date*1000).getTime() : false;

  const apiRole = getAPIRole(userDetails);

  const currentPlan = DEV_PLAN_CONFIG[apiRole];

  const API_SUBSCRIPTION_TYPE = SubType.API;

  const subscribeMutation = useMutation(subscribeToPlan, {
    onSuccess: (data) => {
      const subscriptionUrl = data.url;
      // window.LemonSqueezy.Url.Open(subscriptionUrl);
      window.location.href = subscriptionUrl;
    },
    onError: (error) => {
      alerts.error("Subscription Failed", "Something went wrong. Please try again");
    },
  });

  const handleSubscribeToPlan = async (
    plan: Plan,
    subscription: Subscription
  ) => {
    if (userDetails?.email === undefined) {
      window.location.href = `${AUTH_FRONTEND_URL}`;
    }

    subscribeMutation.mutate({ subscription, plan, token: userToken });
  };

  const manageSubscriptionMutation = useMutation(manageSubscription, {
    onSuccess: (data) => {
      window.location.href = data.url;
    },
    onError: (error) => {
      alerts.error("Error", "An error occurred. Please try again");
    },
  });

  const handleManageSubscription = async (subType: SubType, action?: ActionType) => {
    if (subscriptionInfo === undefined || userToken === undefined) {
      return;
    }

    manageSubscriptionMutation.mutate({token: userToken, subType, action});
  };
  const handleEmailClick = () => {
    const recipient = "entreprise@askyourpdf.com";
    const mailtoUrl = `mailto:${recipient}`;
    window.location.href = mailtoUrl;
  };
  return (
    <Layout hasSider={true} style={{ minHeight: "100vh" }}>
      {subscriptionStatus === "loading" && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
      <AppSidebar />
      <DeveloperSidebar />
      <ContentLayout>
        <Header>
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowLeft size={20} color="#ffffff" />
          </button>
          <p>Developers</p>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://docs.askyourpdf.com/askyourpdf-docs/"
          >
            View Docs
            <ArrowUpRight size={20} color="#ffffff" />
          </a>
        </Header>
        <DevContent>
          <TitleSection>
            <h1>Billing</h1>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.askyourpdf.com/api/"
            >
              View Documentation
              <ArrowUpRight size={20} color="#000000" />
            </a>
          </TitleSection>
          <HeaderSection>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 1,
                    label: (
                      <Link to="/apikeys" replace={true}>
                        API Keys
                      </Link>
                    ),
                  },
                  {
                    key: 2,
                    label: (
                      <Link to="/billing" replace={true}>
                        Billing
                      </Link>
                    ),
                  },
                ],
                style: { fontFamily: "Inter, sans-serif" },
              }}
              trigger={["click"]}
            >
              <MobilePageSwitcher>
                {location.pathname === "/apikeys" ? "API Keys" : "Billing"}
                <CaretDown size={20} color="#667085" />
              </MobilePageSwitcher>
            </Dropdown>
            <ActivePlanInfo>
              <div>
                <h2>Current Plan</h2>
                <PlanBadge
                  $color={
                    currentPlan.name !==
                    DEV_PLAN_CONFIG[UserAPIPlan.API_FREE].name
                      ? "#027A48"
                      : "#344054"
                  }
                  $background={
                    currentPlan.name !==
                    DEV_PLAN_CONFIG[UserAPIPlan.API_FREE].name
                      ? "#ECFDF3"
                      : "#F2F4F7"
                  }
                >
                  {currentPlan.name} Plan
                </PlanBadge>
                {isSubscriptionCanceled && <PlanBadge>Canceled</PlanBadge>}
              </div>
              <p>
                This plan limits your quota to the maximum available for the{" "}
                {currentPlan.name} Plan.{" "}
                <a
                  href={DOCUMENTATION_URL}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Learn More
                </a>
              </p>
            </ActivePlanInfo>
          </HeaderSection>
          <PlanSection>
            <SectionHeader>
              <h1>Upgrade Account</h1>
              <p>Choose the preferred plan that suits your application</p>
            </SectionHeader>
            <PlanItemList>
              <PlanItem
                $active={apiRole === UserAPIPlan.API_FREE || is404Error}
              >
                <PlanItemInfo>
                  <PlanBadge>API Free Plan</PlanBadge>
                  <h1>${DEV_PLAN_CONFIG[UserAPIPlan.API_FREE].price}/month</h1>
                  <div className="plan__benefits">
                    <p>
                      {DEV_PLAN_CONFIG[UserAPIPlan.API_FREE].messages} messages{" "}
                      <span>monthly</span>
                    </p>
                    <p>|</p>
                    <p>
                      {DEV_PLAN_CONFIG[UserAPIPlan.API_FREE].pages} document
                      pages <span>monthly</span>
                    </p>
                    <p>|</p>
                    <p>
                      Maximum {DEV_PLAN_CONFIG[UserAPIPlan.API_FREE].maximumDocuments} documents
                      per <span>month</span>
                    </p>
                  </div>
                </PlanItemInfo>
                {apiRole === UserAPIPlan.API_FREE ? (
                  <CheckCircle size={16} color="#475467" weight="fill" />
                ) : isSubscriptionCanceled ? (
                  <PlanActionButton
                    onClick={()=>{handleManageSubscription(API_SUBSCRIPTION_TYPE)}}
                    disabled={manageSubscriptionMutation.isLoading}
                  >
                    Renew Canceled Plan{" "}
                    {manageSubscriptionMutation.isLoading && (
                      <Spinner
                        style={{ width: "24px", verticalAlign: "middle" }}
                      />
                    )}
                  </PlanActionButton>
                ) : (
                  <PlanActionButton
                    onClick={() => {
                      setConfirmDownGrade(true);
                    }}
                  >
                    Cancel Plan
                  </PlanActionButton>
                )}{" "}
              </PlanItem>
              <PlanItem $active={apiRole === UserAPIPlan.API_PREMIUM}>
                <PlanItemInfo>
                  <PlanBadge $color="#027A48" $background="#ECFDF3">
                    API Pro Plan
                  </PlanBadge>
                  <h1>
                    ${DEV_PLAN_CONFIG[UserAPIPlan.API_PREMIUM].price}/month
                  </h1>
                  {/* <Slider
                    onChange={(value) => setSliderValue(value)}
                    value={sliderValue}
                    min={1}
                    max={10}
                    tooltip={{ open: false }}
                  /> */}
                  <div className="plan__benefits">
                    <p>
                      {DEV_PLAN_CONFIG[UserAPIPlan.API_PREMIUM].messages}{" "}
                      messages <span>monthly</span>
                    </p>
                    <p>|</p>
                    <p>
                      {DEV_PLAN_CONFIG[UserAPIPlan.API_PREMIUM].pages} document
                      pages <span>monthly</span>
                    </p>
                    <p>|</p>
                    <p>
                      Maximum {DEV_PLAN_CONFIG[UserAPIPlan.API_PREMIUM].maximumDocuments} documents
                      per <span>month</span>
                    </p>
                  </div>
                </PlanItemInfo>
                {apiRole === UserAPIPlan.API_PREMIUM ? (
                  <CheckCircle size={16} color="#475467" weight="fill" />
                ) : (
                  <PlanActionButton
                    onClick={() =>
                      handleSubscribeToPlan(
                        Plan.API_PREMIUM,
                        Subscription.MONTHLY
                      )
                    }
                    disabled={subscribeMutation.isLoading}
                  >
                    Buy Plan{" "}
                    {subscribeMutation.isLoading && (
                      <Spinner
                        style={{ width: "24px", verticalAlign: "middle" }}
                      />
                    )}
                  </PlanActionButton>
                )}
              </PlanItem>
              <PlanItem $active={apiRole === UserAPIPlan.API_ENTERPRISE}>
                <PlanItemInfo>
                  <PlanBadge $color="#027A48" $background="#ECFDF3">
                    API Enterprise Plan
                  </PlanBadge>
                  <div className="plan__benefits">
                    <p id="enterprise__plan__text">
                      Custom and flexible pricing for high volume processing
                    </p>
                  </div>
                </PlanItemInfo>
                {apiRole === UserAPIPlan.API_ENTERPRISE ? (
                  <CheckCircle size={16} color="#475467" weight="fill" />
                ) : (
                  <a href="mailto:entreprise@askyourpdf.com">
                    <PlanContactUsButton onClick={handleEmailClick}>
                      Contact Us
                    </PlanContactUsButton>
                  </a>
                )}
              </PlanItem>
            </PlanItemList>
          </PlanSection>
        </DevContent>
      </ContentLayout>
      <DeleteModal
        open={confirmDownGrade}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.60)",
          backdropFilter: "blur(8px)",
        }}
        footer={null}
        width={350}
        closable={false}
        onCancel={() => {}}
      >
        <ModalContentStyles>
          <h1>Downgrade Account</h1>
          <p>
            Are you sure you want to downgrade to a free plan? You'll have
            access to your pro features until the end of your billing period on{" "}
            {subscriptionInfo
              ? new Date(
                  subscriptionInfo.renewal_date * 1000
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                })
              : ""}
          </p>
          <div>
            <button
              onClick={() => setConfirmDownGrade(!confirmDownGrade)}
              disabled={manageSubscriptionMutation.isLoading}
            >
              Go Back
            </button>
            <button
              onClick={()=>{handleManageSubscription(API_SUBSCRIPTION_TYPE, ActionType.SUBSCRIPTION_CANCEL)}}
              disabled={manageSubscriptionMutation.isLoading}
            >
              Downgrade{" "}
              {manageSubscriptionMutation.isLoading && (
                <Spinner style={{ width: "24px" }} />
              )}
            </button>
          </div>
        </ModalContentStyles>
      </DeleteModal>
    </Layout>
  );
}
