import React, { createContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  getUserBillingHistory,
  getUserSubscription,
} from "../services/payment";
import { useAuth } from "./AuthProvider";
import {
  API_SUBSCRIPTION_PLAN_TYPE,
  WEB_SUBSCRIPTION_PLAN_TYPE,
} from "../config/config";

const SubscriptionContext = createContext<any>({});

export const SubscriptionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // Used to manage any component that would rely on the completion status of lemonsqueezy
  const [purchaseComplete, setPurchaseComplete] = useState(false);

  const [billingPage, setBillingPage] = useState(1);

  const { userToken } = useAuth();

  const billingPageSize = 10;

  const queryClient = useQueryClient();

  const {
    data: subscriptionInfoArray,
    status: subscriptionStatus,
    isError: isSubscriptionError,
    error: subscriptionError,
  } = useQuery("userSubscription", async () => getUserSubscription(userToken), {
    enabled: userToken !== undefined,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: billingHistory,
    status: billingStatus,
    isFetching: isBillingFetching,
    isPreviousData: isPreviousBilling,
  } = useQuery(
    ["billingHistory", billingPage],
    async () =>
      await getUserBillingHistory(userToken, billingPage, billingPageSize),
    {
      keepPreviousData: true,
      staleTime: 5000,
      refetchOnWindowFocus: false,
      enabled: userToken !== undefined,
      select: (data) => {
        const billingHistory = data.history;
        const keyedHistory = billingHistory.map(
          (billingItem: any, index: any) => ({
            ...billingItem,
            key: index,
          })
        );
        return { ...data, history: keyedHistory };
      },
    }
  );

  // Prefetch next billing page
  useEffect(() => {
    if (!isPreviousBilling && userToken) {
      queryClient.prefetchQuery({
        queryKey: ["billingHistory", billingPage + 1],
        queryFn: () =>
          getUserBillingHistory(userToken, billingPage + 1, billingPageSize),
      });
    }
  }, [billingHistory, isPreviousBilling, billingPage, queryClient, userToken]);

  const totalBillingRecords = billingHistory
    ? billingPageSize * billingHistory.total_pages
    : 0;

  const userSubscriptionInfo = subscriptionInfoArray?.find(
    (subInfo: any) => subInfo.plan_type === WEB_SUBSCRIPTION_PLAN_TYPE
  );

  const devSubscriptionInfo = subscriptionInfoArray?.find(
    (subInfo: any) => subInfo.plan_type === API_SUBSCRIPTION_PLAN_TYPE
  );

  return (
    <SubscriptionContext.Provider
      value={{
        purchaseComplete,
        setPurchaseComplete,
        subscriptionError,
        isSubscriptionError,
        subscriptionStatus,
        billingHistory,
        billingStatus,
        isBillingFetching,
        totalBillingRecords,
        setBillingPage,
        billingPageSize,
        devSubscriptionInfo,
        userSubscriptionInfo
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export function useSubscription() {
  const context = React.useContext(SubscriptionContext);

  return context;
}

export default SubscriptionContext;
