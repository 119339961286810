import {Modal, Row, Col, Input, Space} from 'antd';
import styled from "styled-components";
import {ReactComponent as CloseIcon} from "../../img/ModalCloseIcon.svg";
import {ReactComponent as UserAvatar} from "../../img/UserAvatar.svg";
// import {ReactComponent as Verify} from '../../img/verify.svg'
import {ReactComponent as NextIcon} from '../../img/next.svg'
import {ReactComponent as LockIcon} from '../../img/Lock.svg'
import React, {useEffect, useMemo, useState} from 'react';
import {ArrowLeft} from '@phosphor-icons/react';
import {PaymentButton} from '../../pages/SettingsPage';
import {path} from '../../routes';
import {useAuth} from '../../providers/AuthProvider';
import AppAlert from '../AppAlert';
import {PAID_ROLES} from '../../config/config';
import {useLocation, useNavigate} from 'react-router-dom';
import {getUserPlans} from "../../utils/utils";
// import { useUpdatePasswordMutation } from '../../hooks/use-UpdatePassword.mutation';

// type userType = {
//   email: string
//   is_active: boolean
//   id: string
//   is_superuser: boolean
//   is_verified: boolean
//   roles: any
// // roles: enum
// }
interface Passwords {
    old: string;
    new: string;
    confirm: string;
}

interface Roles {
    plans: string[],
    api_roles: string[],
}

export default function Profile({currentPath, profile, setProfile}: {
    currentPath: string,
    profile: boolean,
    setProfile: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const [passwordScreen, setPasswordScreen] = useState(false);
    const [password, setPassword] = useState<Passwords>();
    const [error, setError] = useState(false)
    const {userDetails} = useAuth();
    const navigate = useNavigate()
    const location = useLocation();
    // const {mutate, isLoading} = useUpdatePasswordMutation()
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        let Name = name.split(' ')[0];
        const passwords = {
            ...password,
            [Name]: value
        } as Passwords;
        setPassword(passwords);
    };

    async function handlePasswordUpdate() {
        if (!(password?.new === password?.confirm)) {
            return setError(true)
        }
        let data = {password: password?.new}
        console.log('mutate', data)
        // mutate(data)
    }

    const {normal_role, api_role} = useMemo(() => getUserPlans(userDetails?.roles || []), [userDetails?.roles]);



    return (
        <CustomModal
            title={passwordScreen ?
                <p onClick={() => setPasswordScreen(!passwordScreen)}><span><ArrowLeft/></span> Change Password</p>
                : "Profile"}
            open={profile}
            width={636}
            footer={null}
            closeIcon={<WhiteCloseIcon/>}
            onCancel={() => setProfile(!profile)}
            wrapClassName={[path.apiKeys, path.billingInfo].some(subPath => currentPath.includes(subPath)) ? "dev__modal" : ""}
        >
            {passwordScreen ?
                <>
                    <CustomSpace direction="vertical" size='middle'>
                        {error && <AppAlert type="error" message={"confirm password and new password don't match"}/>}
                        <CustomCol>
                            <h1>Old Password</h1>
                            <InputContainer>
                                <LockIcon/>
                                <StyledInput name='old password' onChange={handleOnChange}
                                             placeholder='••••••••••••••••••' value={password?.old}/>
                            </InputContainer>
                        </CustomCol>
                        <CustomRow>
                            <CustomCol>
                                <h1>New Password</h1>
                                <InputContainer>
                                    <LockIcon/>
                                    <StyledInput name='new password' onChange={handleOnChange}
                                                 placeholder='••••••••••••••••••' value={password?.new}/>
                                </InputContainer>
                            </CustomCol>
                            <CustomCol>
                                <h1>Confirm Password</h1>
                                <InputContainer>
                                    <LockIcon/>
                                    <StyledInput name='confirm password' onChange={handleOnChange}
                                                 placeholder='••••••••••••••••••' value={password?.confirm}/>
                                </InputContainer>
                            </CustomCol>
                        </CustomRow>
                        <UpdateButton onClick={handlePasswordUpdate}>Update</UpdateButton>
                    </CustomSpace>
                </> :
                <>
                    <ImageSection>
                        <figure>
                            <UserAvatar/>
                        </figure>
                        <div>
                            <BoldP>Email Address</BoldP>
                            <ThinP>{userDetails?.email}</ThinP>
                        </div>
                    </ImageSection>
                    <ColumnContainer>
                        {/* <Col>
                            <BoldP>Email Address</BoldP>
                            <ThinP>{userDetails?.email}</ThinP>
                        </Col> */}
                        <PlansRow justify={'space-between'} align={'bottom'}>
                            <Col>
                                <BoldP>Plan</BoldP>
                                <ThinP>{normal_role}{' '}Plan</ThinP>
                            </Col>
                            <ManageButton
                                onClick={() => {
                                    if (location.pathname === path.settings) {
                                        return setProfile(!profile)
                                    }
                                    navigate(path.settings)
                                }}
                            >
                                Manage
                            </ManageButton>
                        </PlansRow>
                        <Row style={{cursor: 'pointer'}} justify={'space-between'} align={'bottom'}
                             onClick={() => navigate(path.apiKeys)}>
                            <Col>
                                <BoldP>API Plan</BoldP>
                                <ThinP>{api_role}</ThinP>
                            </Col>
                            <Col><NextIcon/></Col>
                        </Row>
                    </ColumnContainer>
                </>
            }
        </CustomModal>
    );
}
const ManageButton = styled(PaymentButton)`
  position: relative;
  width: 110px;
`
const UpdateButton = styled(PaymentButton)`
  background: #000;
  color: #FEFEFE;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 -15px;
  position: relative;
  width: 200px;
  @media (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
`;
const CustomCol = styled(Col)`
  width: 45%;
  @media (max-width: 576px) {
    margin: auto;
    width: 100%;
  }
`
const CustomRow = styled(Row)`
  width: 100;
  justify-content: space-between;
`
const InputContainer = styled(Row)`
  flex-wrap: nowrap;
  align-items: center;
  background: #F3F5F7;
  padding: 12px 14px;
  gap: 5px;
  border-radius: 12px;
`
const StyledInput = styled(Input.Password)`
  border: none;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  background: #F3F5F7;
  padding: 0px;
  width: 80%;
  margin-left: 8%;

  &:not(:focus) {
    box-shadow: none;
    border-color: transparent;
  }

  input {
    background-color: inherit;
  }

  input::placeholder {
    color: #232627;
  }
`
const CustomSpace = styled(Space)`
  width: 90%;
  margin: auto;
  display: flex;
  margin-top: -4%;

  h1 {
    color: #141718;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px
  }
`
const BoldP = styled.p`
  display: flex;
  align-items: center;
  color: #141718;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.56px;

  span {
    height: 17px;
    margin-left: 5px;
  }
`
const ThinP = styled(BoldP)`
  font-weight: 400;
  letter-spacing: -0.28px;
`
const ImageSection = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10%;
  margin-bottom: 35px;

  figure > SVG {
    width: 55.755px;
    height: 55.755px;
    margin-right: 15px;
    object-fit: cover;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`
const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 0 10px;
    background: #E1E1E1;
    min-height: 393px;
  }

  .ant-modal-body {
    border-radius: 8px;
    margin: auto;
    background: #fff;
    width: 90%;
    min-height: 301px;
    padding: 7% 0px;
  }

  .ant-modal-header {
    display: flex;
    align-items: center;
    padding: 20px 20px;
    border-radius: 8px 8px 0px 0px;
    background: #030303;
    cursor: pointer;

    span {
      margin: 8px;
    }
  }

  .ant-modal-close-x {
    background: #030303;
  }

  .ant-modal-title {
    color: #fff;
    flex-grow: 1;
    font-family: Satoshi, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  @media (max-width: 576px) {
    .ant-modal-body {
      width: 95%;
    }
  }
`;
const WhiteCloseIcon = styled(CloseIcon)`
  path {
    fill: #fff;
  }
`;
const ColumnContainer = styled(Col)`
  gap: 30px;
  padding: 0 6%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PlansRow = styled(Row)`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  height: 70px;
`