import React, { useEffect, useRef } from "react";
import { LandingSection, SectionHead } from "../../styles/landing";
import AppBG from "../../img/AppBg.png";
import MobilePhone from "../../img/MobilePhone.png";
import AppStoreLogo from "../../img/AppStore.svg";
import PlayStoreLogo from "../../img/PlayStore.svg";
import { CTA, Container, StyleFlexCol } from "../../styles/styles";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const AppImageContainer = styled(motion.div)<{
  $backgroundImage?: string;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 48px;
  // position: relative;
  background-image: url(${(props) => props.$backgroundImage});
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  animation: animatedBackground 3s linear infinite alternate;

  img {
    max-width: 100%;
  }

  @keyframes animatedBackground {
    from {
      background-size: 90%;
    }
    to {
      background-size: 100%;
    }
  }
`;

const DownloadButtonContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 48px;
  gap: 16px;

  @media (min-width: 576px) {
    margin-top: 32px;
    margin-bottom: 106px;
    flex-direction: row;
    width: unset;
  }

  button:nth-of-type(1) {
    background: #000000;
    color: #ffffff;
  }

  button:nth-of-type(2) {
    background: #ffffff;
    border: 1px solid #000000;
    color: #000000;
  }
`;

const DownloadButton = styled(CTA)`
  height: 48px;
  padding: 12px 16px 12px 16px;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;

  &:disabled {
    cursor: default;
  }
`;

export default function AppSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const control = useAnimation();

  useEffect(() => {
    if (isInView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, isInView]);

  const textVariant = {
    visible: { opacity: 1, y: 0, transition: { delay: 0.3, ease: "easeIn" } },
    hidden: { opacity: 0, y: 30 },
  };

  const appImageVariant = {
    visible: { opacity: 1, transition: { delay: 0.3, ease: "easeIn" } },
    hidden: { opacity: 0 },
  };

  return (
    <LandingSection ref={ref} id="App-Section">
      <Container>
        <StyleFlexCol>
          <AppImageContainer
            $backgroundImage={AppBG}
            variants={appImageVariant}
            initial="hidden"
            animate={control}
          >
            {/* <img src={AppBG} alt="" /> */}
            <img src={MobilePhone} alt="" />
          </AppImageContainer>
          <SectionHead
            style={{ textAlign: "center" }}
            variants={textVariant}
            initial="hidden"
            animate={control}
          >
            <h1>
             iOS Coming <span>Soon</span>
            </h1>
            <p>
              Take your document chats on the go with AskYourPDF app! Chat,
              track, learn - anytime, anywhere. Available on iOS and Android.
            </p>
          </SectionHead>
          <DownloadButtonContainer
            variants={textVariant}
            initial="hidden"
            animate={control}
          >
            <DownloadButton disabled>
              <img src={AppStoreLogo} alt="" /> App Store
            </DownloadButton>
            <DownloadButton >
              <img src={PlayStoreLogo} alt="" /> Google Play
            </DownloadButton>

          </DownloadButtonContainer>
        </StyleFlexCol>
      </Container>
    </LandingSection>
  );
}
