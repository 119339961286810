import { API_SERVICE_SERVER_URL, AUTH_SERVER_URL } from "../config/config";
import axios from "axios";

export async function createApiKey(name: String, type: String) {
  let data = JSON.stringify({
    name: name,
    type: type,
  });

  let config = {
    method: "post",
    url: `${API_SERVICE_SERVER_URL}/v1/key`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      // 'Authorization': 'Bearer '+auth
    },
    data: data,
  };

  let res = await axios(config);
  if (res !== undefined) {
    return res.data;
  } else {
    return false;
  }
}

export async function deleteApiKey(key: String) {
  let data = "";

  let config = {
    method: "delete",
    url: `${API_SERVICE_SERVER_URL}/v1/keys/${key}`,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      // 'Authorization': 'Bearer '+auth
    },
    data: data,
  };

  let res = await axios(config).catch(function (error) {
    console.log(error);
  });
  if (res !== undefined) {
    return res.data;
  } else {
    return false;
  }
}

export async function apiAnalytics(type: String) {
  let data = "";

  let config = {
    method: "get",
    url: `${API_SERVICE_SERVER_URL}/v1/usage?environment=${type}`,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      // 'Authorization': 'Bearer '+auth
    },
    data: data,
  };

  let res = await axios(config).catch(function (error) {
    //console.log(error);
  });
  if (res !== undefined) {
    return res.data;
  } else {
    return false;
  }
}

export async function getCurrentUser() {
  let data = "";

  let config = {
    method: "get",
    url: `${AUTH_SERVER_URL}/users/me`,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      // 'Authorization': 'Bearer '+auth
    },
    data: data,
  };

  let res = await axios(config).catch(function (error) {});
  if (res !== undefined) {
    let rtdata: any = {};
    //res.data.roles = ["basic"];
    rtdata["data"] = res.data;
    rtdata["keys"] = await listApiKey();
    return rtdata;
  } else {
    let rtdata: any = {};
    rtdata["data"] = { roles: [] };
    rtdata["keys"] = [];
    return rtdata;
  }
}

export async function listApiKey() {
  let data = "";

  let config = {
    method: "get",
    url: `${API_SERVICE_SERVER_URL}/v1/keys`,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      // 'Authorization': 'Bearer '+auth
    },
    data: data,
  };

  let res = await axios(config).catch(function (error) {
    //console.log(error);
  });
  if (res !== undefined) {
    return res.data;
  } else {
    return [];
  }
}
