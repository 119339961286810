import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  LandingSection,
  LandingFlexCol,
  SectionHead,
} from "../../styles/landing";
import { Container } from "../../styles/styles";
import CheckIcon from "../../img/Check.svg";
import { motion, useAnimation, useInView } from "framer-motion";
import { convertToMB, getUserRole } from "../../utils/utils";
import {
  Plan as PlanEnum,
  Subscription,
  subscribeToPlan,
} from "../../services/payment";
import { useAuth } from "../../providers/AuthProvider";
import { useMutation } from "react-query";
import Spinner from "../Spinner";
import {
  AUTH_FRONTEND_URL,
  UserPlan,
  WEB_PLAN_CONFIG,
  WEB_PLAN_PRICES,
} from "../../config/config";
import { alerts } from "../../utils/alerts";

const TabContainer = styled(motion.div)`
  display: flex;
  gap: 4px;
  padding: 4px;
  margin-top: 24px;
  background: #f6f6f8;
  border-radius: 24px;
  align-self: center;

  @media (min-width: 576px) {
    margin-top: 32px;
  }
`;

const Tab = styled.div<{ $active?: boolean }>`
  background: ${(props) => (props.$active ? "#FFFFFF" : "transparent")};
  padding: 8px 16px;
  box-shadow: ${(props) =>
    props.$active
      ? "0px 1px 3px rgba(47, 43, 67, 0.1), inset 0px -1px 0px rgba(47, 43, 67, 0.1)"
      : "0"};
  border-radius: ${(props) => (props.$active ? "24px" : "0")};
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: ${(props) => (props.$active ? "700" : "500")};
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(props) => (props.$active ? "#2f2b43" : "#2F2B4399")};
  }
`;

const PlanContainer = styled(motion.div)`
  margin-top: 48px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 576px) {
    margin-top: 64px;
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Plan = styled.div`
  background: #ffffff;

  border: 1px solid rgba(47, 43, 67, 0.1);
  border-radius: 24px;
`;

const PlanHead = styled.div<{ $background?: string }>`
  box-sizing: border-box;
  min-height: 370px;
  padding: 24px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  background: ${(props) => props.$background || "#F8F9FF"};
  border-bottom: 1px solid rgba(47, 43, 67, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  a {
    text-decoration: none;
    width: 100%;
  }
`;

const PlanHeadContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    margin: 0;
    max-width: 336px;

    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;

    color: rgba(0, 0, 0, 0.6);
  }

  .slashed__price {
    color: #2f2b43;
    font-family: Satoshi, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-decoration: line-through;
  }
`;

const PlanFooter = styled.div`
  padding: 24px;

  p {
    margin: 0;
    margin-bottom: 16px;

    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: -0.01em;

    color: rgba(47, 43, 67, 0.6);
  }

  ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  ul > li {
    display: flex;
    align-items: center;
    gap: 12px;

    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #2f2b43;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  h2 {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;

    letter-spacing: -0.02em;

    color: #2f2b43;
  }

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: rgba(47, 43, 67, 0.6);
  }
`;

const PlanType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #2f2b43;
  }

  div {
    background: #2F2B43;
    border-radius: 20px;
    padding: 0px 8px;

    p {
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
`;

const Badge = styled.div`
background: #2F2B43;
border-radius: 20px;
padding: 0px 8px;

p {
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

`

const PlanCTA = styled.button<{ $background?: string; $color?: string }>`
  background: ${(props) => props.$background || "#ffffff"};
  color: ${(props) => props.$color || "#000000"};
  padding: 12px 16px 12px 16px;
  border: 1px solid rgba(47, 43, 67, 0.1);
  box-shadow: 0px 1px 3px rgba(47, 43, 67, 0.1),
    inset 0px -1px 0px rgba(47, 43, 67, 0.1);
  border-radius: 12px;

  font-family: Satoshi, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  text-align: center;
  letter-spacing: -0.01em;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const PlanSectionContainer = styled(Container)`
  @media (min-width: 576px) {
    max-width: unset;
  }

  @media (min-width: 768px) {
    max-width: unset;
  }

  @media (min-width: 992px) {
    max-width: unset;
  }

  @media (min-width: 1200px) {
    max-width: unset;
  }
`;

// million-ignore
export default function Plans() {
  const [activePlan, setActivePlan] = useState<"month" | "year">("month");
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const control = useAnimation();

  const textVariant = {
    visible: { opacity: 1, y: 0, transition: { delay: 0.3, ease: "easeIn" } },
    hidden: { opacity: 0, y: 30 },
  };

  const planVariant = {
    visible: { opacity: 1, x: 0, transition: { delay: 0.3, ease: "easeIn" } },
    hidden: { opacity: 0, x: -100 },
  };

  const { userToken, userDetails } = useAuth();

  const userRole = getUserRole(userDetails);

  useEffect(() => {
    if (isInView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, isInView]);

  const subscribeMutation = useMutation(subscribeToPlan, {
    onSuccess: (data) => {
      const subscriptionUrl = data.url;
      // window.LemonSqueezy.Url.Open(subscriptionUrl);
      window.location.href = subscriptionUrl;
    },
    onError: (error) => {
      alerts.error("Subscription Failed", "Something went wrong. Please try again");
    },
  });

  const handleSubscribeToPlan = async (
    plan: PlanEnum,
    subscription: Subscription
  ) => {
    if (userDetails?.email === undefined) {
      window.location.href = `${AUTH_FRONTEND_URL}`;
    }

    if (plan === PlanEnum.PREMIUM && userRole === UserPlan.PREMIUM) {
      return;
    }

    if (plan === PlanEnum.PRO && userRole === UserPlan.PRO) {
      return;
    }

    subscribeMutation.mutate({ subscription, plan, token: userToken });
  };
  return (
    <LandingSection ref={ref} id="Plans">
      <PlanSectionContainer>
        <LandingFlexCol>
          <SectionHead
            style={{ textAlign: "center" }}
            variants={textVariant}
            initial="hidden"
            animate={control}
          >
            <h1>
              Plans for <span>everyone</span>
            </h1>
            <p>
              Experience the future of document interaction with AskYourPDF.
              Choose the plan that fits your needs and start engaging with your
              documents like never before:
            </p>
          </SectionHead>
          <TabContainer
            variants={textVariant}
            initial="hidden"
            animate={control}
          >
            <Tab
              onClick={() => {
                setActivePlan("month");
              }}
              $active={activePlan === "month"}
            >
              <p>Monthly</p>
            </Tab>
            <Tab
              onClick={() => {
                setActivePlan("year");
              }}
              $active={activePlan === "year"}
            >
              <p>Annual </p>
              <Badge>
                <p>save 25%</p>
              </Badge>
            </Tab>
          </TabContainer>
          <PlanContainer
            variants={planVariant}
            initial="hidden"
            animate={control}
          >
            <Plan>
              <PlanHead>
                <PlanHeadContent>
                  <PlanType>
                    <p>Free</p>
                  </PlanType>
                  <PriceInfo>
                    <h2>${WEB_PLAN_PRICES[activePlan].free}</h2>
                    <p>/{activePlan}</p>
                  </PriceInfo>
                  <p>
                    Perfect for getting started! Experience the magic of
                    AI-enhanced documents at no cost. Includes 100 chats per
                    month.
                  </p>
                </PlanHeadContent>
                <PlanCTA
                  onClick={() => {
                    // navigate to conversations
                  }}
                  disabled={subscribeMutation.isLoading}
                >
                  Get Started
                </PlanCTA>
              </PlanHead>
              <PlanFooter>
                <p>Includes:</p>
                <ul>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[0].maxNumberPagesPerDoc} pages per document
                    (max{" "}
                    {convertToMB(WEB_PLAN_CONFIG[0].maxFileSizePerDoc).toFixed(
                      0
                    )}
                    MB)
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Max upload size:{" "}
                    {convertToMB(WEB_PLAN_CONFIG[0].maxFileSizePerDoc).toFixed(
                      0
                    )}
                    MB
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[0].maxNumberQuestionsPerDay} questions per
                    day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[0].maxNumberConversationsPerDay}{" "}
                    conversations per day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[0].maxNumberDocsPerDay} document per day
                  </li>
                </ul>
              </PlanFooter>
            </Plan>
            <Plan>
              <PlanHead $background="#F8F9FF">
                <PlanHeadContent>
                  <PlanType>
                    <p>Premium</p>
                    {/* <div>
                      <p>Most Popular</p>
                    </div> */}
                  </PlanType>
                  <PriceInfo>
                    <h2>${WEB_PLAN_PRICES[activePlan].premium}</h2>
                    <p>/{activePlan}</p>
                  </PriceInfo>
                  <p className="slashed__price">{activePlan === "month" ? "$9.99" : "$99.99"}</p>
                  <p>
                    Elevate your document interaction with unlimited chats, full
                    conversation history, and priority support.
                  </p>
                </PlanHeadContent>
                <PlanCTA
                  onClick={() => {
                    handleSubscribeToPlan(
                      PlanEnum.PREMIUM,
                      activePlan === "month"
                        ? Subscription.MONTHLY
                        : Subscription.YEARLY
                    );
                  }}
                  disabled={
                    subscribeMutation.isLoading || userRole === UserPlan.PREMIUM
                  }
                >
                  Get Started{" "}
                  {subscribeMutation.isLoading && (
                    <Spinner style={{ width: "20px" }} />
                  )}
                </PlanCTA>
              </PlanHead>
              <PlanFooter>
                <p>Includes:</p>
                <ul>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[1].maxNumberPagesPerDoc} pages per document
                    (max{" "}
                    {convertToMB(WEB_PLAN_CONFIG[1].maxFileSizePerDoc).toFixed(
                      0
                    )}
                    MB){" "}
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Max upload size:{" "}
                    {convertToMB(WEB_PLAN_CONFIG[1].maxFileSizePerDoc).toFixed(
                      0
                    )}
                    MB{" "}
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[1].maxNumberQuestionsPerDay} questions per
                    day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[1].maxNumberConversationsPerDay}{" "}
                    conversations per day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[1].maxNumberDocsPerDay} documents per day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Chrome Extension Access
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    API Access
                  </li>
                </ul>
              </PlanFooter>
            </Plan>
            <Plan>
              <PlanHead $background="#F8F9FF">
                <PlanHeadContent>
                  <PlanType>
                    <p>Pro</p>
                    <Badge>
                      <p>Most Popular</p>
                    </Badge>
                  </PlanType>
                  <PriceInfo>
                    <h2>${WEB_PLAN_PRICES[activePlan].pro}</h2>
                    <p>/{activePlan}</p>
                  </PriceInfo>
                  <p className="slashed__price">{activePlan === "month" ? "$14.99" : "$149.99"}</p>
                  <p>
                    Elevate your document interaction with unlimited chats, full
                    conversation history, and priority support.
                  </p>
                </PlanHeadContent>
                <PlanCTA
                  $background="#000000"
                  $color="#ffffff"
                  onClick={() => {
                    handleSubscribeToPlan(
                      PlanEnum.PRO,
                      activePlan === "month"
                        ? Subscription.MONTHLY
                        : Subscription.YEARLY
                    );
                  }}
                  disabled={
                    subscribeMutation.isLoading || userRole === UserPlan.PRO
                  }
                >
                  Get Started{" "}
                  {subscribeMutation.isLoading && (
                    <Spinner style={{ width: "20px" }} />
                  )}
                </PlanCTA>
              </PlanHead>
              <PlanFooter>
                <p>Includes:</p>
                <ul>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[2].maxNumberPagesPerDoc} pages per document
                    (max{" "}
                    {convertToMB(WEB_PLAN_CONFIG[2].maxFileSizePerDoc).toFixed(
                      0
                    )}
                    MB){" "}
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Max upload size:{" "}
                    {convertToMB(WEB_PLAN_CONFIG[2].maxFileSizePerDoc).toFixed(
                      0
                    )}
                    MB{" "}
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[2].maxNumberQuestionsPerDay} questions per
                    day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[2].maxNumberConversationsPerDay}{" "}
                    conversations per day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[2].maxNumberDocsPerDay} documents per day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Chrome Extension Access
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Unlimited ChatGPT Plugin Question
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    API Access
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    OCR Support - Coming Soon
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Longer Reply Length
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Priority support
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Priority access to new features
                  </li>
                </ul>
              </PlanFooter>
            </Plan>
            <Plan>
              <PlanHead>
                <PlanHeadContent>
                  <PlanType>
                    <p>Enterprise</p>
                  </PlanType>
                  <PriceInfo>
                    <h2>{WEB_PLAN_PRICES[activePlan].enterprise}</h2>
                  </PriceInfo>
                  <p>
                    Go all out on features! Get everything in the Pro Plan, plus
                    priority document processing and access to new features.
                  </p>
                </PlanHeadContent>
                <a href="mailto:entreprise@askyourpdf.com">
                  <PlanCTA
                    style={{ width: "100%" }}
                    disabled={
                      subscribeMutation.isLoading ||
                      userRole === UserPlan.ENTERPRISE
                    }
                  >
                    Contact Us
                  </PlanCTA>
                </a>
              </PlanHead>
              <PlanFooter>
                <p>Includes:</p>
                <ul>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {/*{WEB_PLAN_CONFIG[3].maxNumberPagesPerDoc} */}
                    Unlimited pages per document
                    {/*(max{" "}*/}
                    {/*{convertToMB(WEB_PLAN_CONFIG[3].maxFileSizePerDoc).toFixed(*/}
                    {/*  0*/}
                    {/*)}*/}
                    {/*MB){" "}*/}
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Custom Max upload size
                    {/*Max upload size:{" "}*/}
                    {/*{convertToMB(WEB_PLAN_CONFIG[3].maxFileSizePerDoc).toFixed(*/}
                    {/*  0*/}
                    {/*)}*/}
                    {/*MB{" "}*/}
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[3].maxNumberQuestionsPerDay} questions per
                    day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    {WEB_PLAN_CONFIG[3].maxNumberConversationsPerDay}{" "}
                    conversations
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Unlimited documents per day
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Customizable Link
                  </li>
                  <li>
                    <img src={CheckIcon} alt="" />
                    Dedicated 24/7 Support
                  </li>
                </ul>
              </PlanFooter>
            </Plan>
          </PlanContainer>
        </LandingFlexCol>
      </PlanSectionContainer>
    </LandingSection>
  );
}
