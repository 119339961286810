import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../img/Logo.svg";
import DiscordIcon from "../img/Discord.svg";
import TwitterIcon from "../img/Twitter.svg";
import { path } from "../routes";
import { AUTH_FRONTEND_URL, DOCUMENTATION_URL } from "../config/config";

const FooterContainer = styled.footer``;

const UpperSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  padding: 50px 120px;

  @media (min-width: 780px) {
    flex-direction: row;
    gap: unset;
    justify-content: space-between;
  }
`;

const LogoContainer = styled.div`
  path {
    fill: #000000;
  }
`;

const QuickLinks = styled.div`
  ul {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    list-style: none;
    margin: 0;

    a {
      font-family: Satoshi, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      text-decoration: none;
    }

    @media (min-width: 780px) {
      flex-direction: row;
    }
  }
`;

const Socials = styled.div`
  display: flex;
  gap: 16px;

  img {
    display: block;
  }
`;

const CopyrightSection = styled.div`
  background: #f6f6f8;
  padding-block: 24px;

  text-align: center;
  font-family: Satoshi, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #667085;
`;

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <FooterContainer>
      <UpperSection>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <QuickLinks>
          <ul>
            <li>
              <Link to={path.FAQs}>FAQ</Link>
            </li>
            <li>
              <Link to={path.PrivacyPolicy}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={path.TermsAndCondition}>Terms & Conditions</Link>
            </li>
            <li>
              <Link to={"/conversations"}>Getting Started</Link>
            </li>
          </ul>
        </QuickLinks>
        <Socials>
          <a
            href="https://discord.gg/bt6SCGEtu9"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={DiscordIcon} alt="" />
          </a>
          <a
            href="https://twitter.com/askyourpdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={TwitterIcon} alt="" />
          </a>
        </Socials>
      </UpperSection>
      <CopyrightSection>
        BlockTechnology OÜ. All rights reserved. &copy; {year}
      </CopyrightSection>
    </FooterContainer>
  );
}
