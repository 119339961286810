import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AUTH_FRONTEND_URL } from "../config/config";
import { useAuth } from "../providers/AuthProvider";
import { Space, Spin } from "antd";
import { Loading } from "../styles/styles";
import Spinner from "./Spinner";

export default function AuthGuard() {
  const { auth } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    let timeoutId: NodeJS.Timer;

    const redirectToLogin = () => {
      const loginUrl = new URL(`${AUTH_FRONTEND_URL}/login`);
      loginUrl.searchParams.append("next", pathname);
      window.location.href = loginUrl.toString();
    };

    // Check the initial state of auth
    if (!auth?.access_token) {
      // If not authenticated, set a timeout to redirect the user if loading takes too long
      timeoutId = setTimeout(redirectToLogin, 3000);
    }

    const checkAuth = () => {
      if (auth?.access_token) {
        // If user is authenticated, clear the timeout to prevent redirection
        clearTimeout(timeoutId);
      } else if (auth !== undefined && auth !== null) {
        redirectToLogin();
      }
    };

    checkAuth();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [auth, pathname]);

  if (auth === null || auth === undefined) {
    return (
      <Loading>
        <Space size="middle">
          <Spinner />
        </Space>
      </Loading>
    );
  }

  return <Outlet />;
}
