import React from "react";
import styled from "styled-components";
import Modal from "antd/es/modal/Modal";
import { XCircle } from "@phosphor-icons/react";
import { Input } from "antd";
import { ReactComponent as PaymentMethodFormIcon } from "../../img/PaymentMethodFormIcon.svg";

type modalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PaymentMethodModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    padding: 0;
  }
`;

const ModalHeader = styled.div`
  border-radius: 8px 8px 0px 0px;
  padding-block: 10px;
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;

  h1 {
    color: #fff;
    font-family: Satoshi, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;

    svg {
      display: block;
    }
  }
`;

const ModalBody = styled.div<{ $background?: string }>`
  background: ${(props) => (props.$background ? props.$background : "#ffffff")};
  border-radius: 0px 0px 8px 8px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  p {
    font-family: Satoshi, sans-serif;
    color: #141718;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.14px;
    margin-bottom: 6px;
  }

  input {
    font-family: Satoshi, sans-serif;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 14px;

    &:hover {
      border: 1px solid #d0d5dd;
    }

    &:focus {
      border-color: #d0d5dd;
    }
  }

  .ant-input-affix-wrapper {
    border-color: #d0d5dd;
    padding: 10px 14px 10px 10px; 
    .ant-input-prefix {
      margin-inline-end: 8px;
    }
    &:hover {
      border: 1px solid #d0d5dd;
    }

    &:focus {
      border-color: #d0d5dd;
    }
    input {
      box-shadow: unset;
      &:hover {
        border: none;
      }
    }
  }
`;

const FormContent = styled.form`
  padding-block: 16px;
  padding-left: 30px;
  padding-right: 45px;
`;

const RowInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalButton = styled.button`
  border-radius: 8px;
  margin-top: 20px;
  border: 1px solid #000;
  background: #000;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 8px 14px;

  color: #fff;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  cursor: pointer;
`;

export default function UpdatePaymentMethodDesktop({
  open,
  setOpen,
}: modalProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PaymentMethodModal
      open={open}
      onCancel={handleClose}
      footer={null}
      closable={false}
      width={595}
      destroyOnClose
      wrapClassName="dev__modal"
    >
      <ModalHeader>
        <h1>Update Payment Method</h1>
        <button onClick={handleClose}>
          <XCircle size={34} color="#FFFFFF" />
        </button>
      </ModalHeader>
      <ModalBody>
        <FormContent
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputContainer>
            <p>Card Number</p>
            <Input prefix={<PaymentMethodFormIcon />} />
          </InputContainer>
          <RowInputContainer>
            <InputContainer style={{ marginBottom: 0 }}>
              <p>Expiry Date</p>
              <Input placeholder="MM/YY" />
            </InputContainer>
            <InputContainer style={{ marginBottom: 0 }}>
              <p>CVV</p>
              <Input placeholder="***" />
            </InputContainer>
          </RowInputContainer>
          <ModalButton>Save Details</ModalButton>
        </FormContent>
      </ModalBody>
    </PaymentMethodModal>
  );
}
