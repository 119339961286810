import React, { useState } from "react";
import { Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import styled from "styled-components";
import { ReactComponent as MessageSquareLarge } from "../img/MessageSquareLarge.svg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Container } from "../styles/styles";
import { ReactComponent as PlayButton } from "../img/PlayButton.svg";
import { CollapseStyle, PanelStyle } from "../styles/faq";
import { MinusCircle, PlusCircle } from "@phosphor-icons/react";
import AppSidebar from "../components/AppSidebar";
import MobileHeader from "../components/MobileHeader";
import TutorialCover from "../img/TutorialCover.png";
import AppHeader from "../components/AppHeader";
import YouTube from "react-youtube";
import { path } from "../routes";

const AppContainer = styled.div`
  border-radius: 16px;
  background: #ffffff;
  width: 100%;
  max-width: 345px;
  margin-inline: auto;

  @media (min-width: 992px) {
    min-height: 640px;
    max-width: unset;
  }
`;

const IntroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 80px;
  padding-inline: 40px;
  gap: 16px;

  button {
    padding: 16px 24px;
    border-radius: 12px;
    width: 236px;
    border: none;
    background: #000000;
    color: #ffffff;
    font-size: 16px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.32px;
    cursor: pointer;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    gap: unset;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: solid 1px #e4e7ec;
    padding-block: 30px;
    padding-inline: 40px;
  }
`;

const IntroText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;

  div {
    max-width: 303px;
  }
  h1 {
    margin: 0;
    margin-bottom: 4px;
    color: #141718;
    font-size: 20px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.96px;
  }

  p {
    margin: 0;
    color: #141414;
    font-size: 14px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.28px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    gap: 21px;
    text-align: left;
    align-items: unset;
    h1 {
      font-size: 24px;
      margin-bottom: unset;
    }
    p {
      color: #8a91a8;
      font-size: 14px;
    }
  }
`;

const GetStartedSection = styled.section`
  display: none;
  padding-top: 26px;
  padding-left: 51px;
  padding-right: 40px;

  h2,
  p {
    margin: 0;
  }

  h2 {
    color: #141718;
    font-size: 20px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.8px;
  }

  p {
    color: #8a91a8;
    font-size: 14px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.28px;
  }

  @media (min-width: 992px) {
    display: block;
  }
`;

const TutorialContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 6px;
`;

const VideoContent = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16.44px;
  max-width: 482px;
  height: 285px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
`;

const ChatCollapseStyle = styled(CollapseStyle)`
  margin: unset;
  border-bottom: unset;
`;

const ChatPanelStyle = styled(PanelStyle)`
  padding: 3px 0;
  .ant-collapse-header-text {
    color: #101828;
    font-size: 16px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  p {
    color: #667085;
    font-size: 14px;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

const FAQContent = styled.div``;

const AppLayout = styled(Layout)`
  .ant-layout-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #f8f8f8;
    padding-block: 4rem;
  }
  @media (min-width: 992px) {
    margin-left: 72px;

    .ant-layout-content {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
    }
  }
`;

const faqData: { id: number; Question: string; answer: string }[] = [
  {
    id: 1,
    Question: "What is this application about?",
    answer: " This application lets you chat with any document you upload.",
  },
  {
    id: 2,
    Question: "How does it work?",
    answer:
      "The application uses artificial intelligence to analyze the content of the document and provide a chat interface for users to ask questions get answers.",
  },
  {
    id: 3,
    Question: "Can I upload any document?",
    answer:
      "We support multiple document formats ('.pdf', '.txt', '.ppt', '.pptx', '.csv','.epub', '.rtf')",
  },
  {
    id: 4,
    Question: "Is it necessary to create an account to use the application?",
    answer:
      "No, you do not need to create an account to use the application, but there are restrictions.",
  },
  {
    id: 5,
    Question: "Can I chat with other users of the application?",
    answer:
      "No, the chat interface is only for interacting with the content of the document",
  },
];

const VideoLightbox = React.lazy(() => import("../components/VideoLightbox"));

export default function ChatPage() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const navigate = useNavigate();
  const { docID } = useParams();

  if (docID)
    return <Navigate to={`/conversations/d/${docID}`} replace={true} />;
  const videoId = "WPPA4mDjzyE";
  const opts = {
    width: "482", // Set your desired width
    height: "285", // Set your desired height
    playerVars: {
      autoplay: 1, // Set to 1 to enable autoplay
      mute: 1,
    },
  };
  return (
    <Layout hasSider style={{ minHeight: "100vh" }}>
      <AppSidebar />
      <AppLayout>
        <AppHeader />
        <MobileHeader />
        <Content>
          <Container style={{ width: "100%" }}>
            <AppContainer>
              <IntroSection>
                <IntroText>
                  <MessageSquareLarge />
                  <div>
                    <h1>Start your first conversation</h1>
                    <p>
                      Begin a conversation with me by uploading a document. I'll
                      respond with anything you need based on the document
                    </p>
                  </div>
                </IntroText>
                <button
                  onClick={() => {
                    navigate(path.upload);
                  }}
                >
                  Start Conversation
                </button>
              </IntroSection>
              <GetStartedSection>
                <h2>Getting Started</h2>
                <p>Short guides to get you started</p>
                <TutorialContent>
                  <VideoContent>
                    <YouTube videoId={videoId} opts={opts} />
                  </VideoContent>
                  <FAQContent>
                    <ChatCollapseStyle
                      accordion
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIconPosition={"end"}
                      expandIcon={({ isActive }: any) =>
                        isActive ? (
                          <MinusCircle size={20} color="#98A2B3" />
                        ) : (
                          <PlusCircle size={20} color="#98A2B3" />
                        )
                      }
                    >
                      {faqData.map((item) => (
                        <ChatPanelStyle
                          header={item.Question}
                          key={item.id.toString()}
                          style={{ border: "none" }}
                        >
                          <p>{item.answer}</p>
                        </ChatPanelStyle>
                      ))}
                    </ChatCollapseStyle>
                  </FAQContent>
                </TutorialContent>
              </GetStartedSection>
            </AppContainer>
          </Container>
        </Content>
      </AppLayout>
      <React.Suspense>
        <VideoLightbox open={lightboxOpen} setOpen={setLightboxOpen} />
      </React.Suspense>
    </Layout>
  );
}
