export const path = Object.freeze({
 home:"/",
 FAQs:'/faqs',
 PrivacyPolicy:"/privacy",
 chat: "/chat/:docID?",
 upload: "/file-upload",
 documents: "/documents/:docID?",
 conversations: "/conversations/:idType?/:id?",
 TermsAndCondition:"/terms",
 settings: "/settings",
 gptUpload: "/upload",
 apiKeys: "/apikeys",
 billingInfo: "/billing",
 billingUpgrade: "/billing/upgrade",
})