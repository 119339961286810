import React, { useState, useRef, useEffect } from "react";
import { Container } from "../../styles/styles";
import styled from "styled-components";
import HeroImage from "../../img/Hero.png";
import UploadIcon from "../../img/Upload.svg";
import ArrowRightIcon from "../../img/ArrowRight.svg";
import ProductHuntIcon from "../../img/ProductHunt.svg";
import { LandingFlexCol } from "../../styles/landing";
import DocumentUploadModal from "../Modals/DocumentUploadModal";
import { motion, useAnimation, useInView } from "framer-motion";

const HeroContainer = styled.section<{ $backgroundImage?: string }>`
  background-color: #0a0a0a;
  background-image: url(${(props) => props.$backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-block: 150px;

  @media (min-width: 576px) {
    padding-block: 250px;
  }

  @media (min-width: 1200px) {
    background-size: 90%;
    animation: animatedBackground 3s linear infinite alternate;

    @keyframes animatedBackground {
      from {
        background-size: 90%;
      }
      to {
        background-size: 100%;
      }
    }
  }
`;

const HeroText = styled(motion.div)`
  h1 {
    margin: 0;
    color: #ffffff;
    font-family: "Eudoxus Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
    max-width: 368px;
  }

  h1:nth-of-type(2) {
    margin-bottom: 24px;
  }

  p {
    margin: 0 auto;
    max-width: 588px;
    color: #ffffff;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }

  @media (min-width: 390px) {
    h1 {
      font-size: 41px;
    }
  }

  @media (min-width: 576px) {
    text-align: center;

    h1 {
      font-size: 38px;
      line-height: 68px;
      max-width: unset;
    }
  }

  @media (min-width: 992px) {
    h1 {
      font-size: 50px;
    }
  }
`;

const HeroActions = styled(motion.div)`
  margin-top: 32px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 0;
    box-shadow: 0px 1px 3px rgba(47, 43, 67, 0.1),
      inset 0px -1px 0px rgba(47, 43, 67, 0.1);
    border-radius: 12px;
    padding: 12px 16px;
    background: #edb01a;

    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }

  button:nth-of-type(2) {
    background: #ffffff;
    border: 1px solid rgba(47, 43, 67, 0.1);
  }

  @media (min-width: 576px) {
    align-items: stretch;
    flex-direction: row;
  }
`;

const BadgeContainer = styled(motion.div)`
  margin-bottom: 28px;

  @media (min-width: 576px) {
    margin-bottom: 36px;
  }
`;
const FormatContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const FormatBox = styled(motion.div)`
  border-radius: 8px;
  border: 1px solid rgba(234, 236, 240, 0.01);
  background: rgba(249, 250, 251, 0.07);
  display: flex;
  padding: 2px 8px;
  margin-right: 5px;
  align-items: center;
  color: #a6a6a6;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;
const Badge = styled.a`
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(220, 218, 226, 0.07);
  border-radius: 20px;
  padding: 0px 8px 0px 6px;
  gap: 8px;
  cursor: pointer;
  width: fit-content;

  text-decoration: none;

  p {
    margin: 0;
    color: #ffffff;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  }

  div {
    background: #383838;
    border-radius: 20px;
    padding: 0px 8px 0px 8px;
  }

  @media (min-width: 576px) {
    width: unset;
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }

  @media (min-width: 360px) {
    p {
      font-size: 14px;
    }
  }
`;

export default function Hero() {
  const ref = useRef(null);
  // const isInView = useInView(ref, { once: true });
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  // const control = useAnimation();
  const formats = ["PDF", "TXT", "PPT", "PPTX", "CSV", "EPUB", "RTF"];
  // const badgeVariant = {
  //   visible: { opacity: 1, y: 0, transition: { delay: 0.1, ease: "easeIn" } },
  //   hidden: { opacity: 0, y: -30 },
  // };

  // const textVariant = {
  //   visible: { opacity: 1, y: 0, transition: { delay: 0.1, ease: "easeIn" } },
  //   hidden: { opacity: 0, y: 30 },
  // };

  // useEffect(() => {
  //   if (isInView) {
  //     control.start("visible");
  //   } else {
  //     control.start("hidden");
  //   }
  // }, [control, isInView]);

  return (
    <HeroContainer $backgroundImage={HeroImage} ref={ref}>
      <Container>
        <LandingFlexCol>
          <BadgeContainer
          >
            <Badge
              href="https://docs.askyourpdf.com/askyourpdf-docs/how-to-use-askyourpdf-plugin-for-chatgpt"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div>
                <p>Get Our Plugin</p>
              </div>
              <p>Install directly on ChatGPT</p>
              <img src={ArrowRightIcon} alt="" />
            </Badge>
          </BadgeContainer>
          <HeroText>
            <h1>It's not just reading anymore,</h1>
            <h1>
              it's a <span style={{ color: "#EDB01A" }}>conversation</span>
            </h1>
            <p>
              Say hello to documents that respond to you! With AskYourPDF, your
              reading isn't just simple, it's fun!
            </p>
          </HeroText>
          <HeroActions
          >
            <button
              onClick={() => {
                setIsUploadModalOpen(true);
              }}
            >
              <img src={UploadIcon} alt="" /> Upload Document
            </button>
            {/* <button>
              <img src={ProductHuntIcon} alt="" />
              Find Us On Product Hunt
            </button> */}
          </HeroActions>
          <HeroText>
            <p>Supported formats</p>
          </HeroText>
          <FormatContainer>
            {formats.map((format: string, index: number) => (
              <FormatBox key={index}>{format}</FormatBox>
            ))}
          </FormatContainer>
        </LandingFlexCol>
      </Container>
      <DocumentUploadModal
        open={isUploadModalOpen}
        setOpen={setIsUploadModalOpen}
      />
    </HeroContainer>
  );
}
