import React from "react";
import Navbar from "../components/Navbar";
import {PageContainer} from "../styles/styles";
import Footer from "../components/Footer";
import Waitlist from "../components/Waitlist";
import Hero from "../components/Landing/Hero";
import Features from "../components/Landing/Features";
import Plans from "../components/Landing/Plans";
import AppSection from "../components/Landing/AppSection";
import Products from "../components/Landing/Products";
import {Helmet} from "react-helmet";

export default function LandingPage() {
    return (
        <div>
            <Helmet>
                <meta name="description"
                      content="AskYourPDF is an innovative platform that leverages the power of AI
                      to seamlessly transform any PDF into responsive and intelligent conversational partners.
                       It’s like GPT4 which uses your uploaded document as its only source of truth"
                />
            </Helmet>
            <PageContainer>
                <Navbar/>
                <Hero/>
                <Features/>
                <Plans/>
                <Products/>
                <AppSection/>
                <Waitlist/>
                <Footer/>
            </PageContainer>
        </div>
    );
}
