import React, {useState} from "react";
import {Container} from "../styles/styles";
import ChevronRight from "../img/ChevronRight.svg";
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import MenuIcon from "../img/MenuIcon.svg";
import CloseIcon from "../img/CloseIcon.svg";
import {ReactComponent as Logo} from "../img/Logo.svg";
import {path} from "../routes";
import {AUTH_FRONTEND_URL, DOCUMENTATION_URL} from "../config/config";
import {useAuth} from "../providers/AuthProvider";

const Header = styled.header`
  position: fixed;
  top: 21px;
  width: 100%;
  z-index: 10;
`;

const LogoContainer = styled.div`
  path {
    fill: #ffffff;
  }

  @media (min-width: 768px) {
    path {
      fill: #edb01a;
    }
  }
`;

const NavContainer = styled(Container)`
  display: flex;
  justify-content: center;

  a,
  li,
  button {
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }
`;

const Content = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 24px;
  background: rgba(13, 13, 13, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(18px);
  border-radius: 16px;
  padding: 12px;

  ul {
    padding-left: 0;
    display: flex;
    gap: 24px;
    list-style: none;
    margin: 0;

    a,
    li {
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6);
      text-decoration: none;
    }
  }

  @media (min-width: 768px) {
    width: unset;
  }
`;

const NavMenu = styled.nav`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

const CTA = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  gap: 8px;
  border: none;
  color: #ffffff;
  padding: 8px 16px;
  background: #313131;
  border-radius: 12px;
  font-weight: 700;
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  div {
    cursor: pointer;

    @media (min-width: 768px) {
      display: none;
    }
  }
`;

const MobileMenu = styled.nav<{ $open?: boolean }>`
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  margin-inline: auto;
  background: #181818;
  width: 90%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: ${(props) => (props.$open ? "block" : "none")};

  ul {
    flex-direction: column;
    padding-block: 24px;
    padding-inline: 20px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

// million-ignore
function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const {userToken, userDetails} = useAuth();

    const handleScrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({behavior: "smooth"});
        }
    };

    const handleGetStartedClick = () => {
        if (userDetails && userDetails.email) {
            navigate("/conversations");
        } else {
            const loginUrl = new URL(`${AUTH_FRONTEND_URL}/login`);
            loginUrl.searchParams.append("next", "/conversations");
            window.location.href = loginUrl.toString();
        }
    };
    return (
        <Header>
            <NavContainer>
                <Content>
                    <LogoContainer>
                        <Logo/>
                    </LogoContainer>
                    <NavMenu>
                        <ul>
                            <li>
                                <Link to={path.home}>Home</Link>
                            </li>
                            <li>
                                <Link to={"/conversations"}>✨ Conversations</Link>
                            </li>
                            <li onClick={() => handleScrollToSection("Plans")}>
                                <Link to={path.home}>Pricing</Link>
                            </li>
                            <li onClick={() => handleScrollToSection("App-Section")}>
                                <Link to={path.home}>Download</Link>
                            </li>
                            <li>
                                <a href={DOCUMENTATION_URL} target="_blank" rel="noopener noreferrer">Developers</a>
                            </li>
                        </ul>
                    </NavMenu>
                    <MobileMenu $open={menuOpen}>
                        <ul>
                            <li>
                                <Link to={path.home}>Home</Link>
                            </li>
                            <li>
                                <Link to={"/conversations"}>✨ Conversations</Link>
                            </li>
                            <li onClick={() => handleScrollToSection("Plans")}>
                                <Link to={path.home}>Pricing</Link>
                            </li>
                            <li onClick={() => handleScrollToSection("App-Section")}>
                                <Link to={path.home}>Download</Link>
                            </li>
                        </ul>
                    </MobileMenu>
                    <Actions>
                        <CTA onClick={handleGetStartedClick}>
                            Get Started <img src={ChevronRight} alt=""/>
                        </CTA>
                        <div
                            onClick={() => {
                                setMenuOpen(!menuOpen);
                            }}
                        >
                            {menuOpen ? (
                                <img src={CloseIcon} alt=""/>
                            ) : (
                                <img src={MenuIcon} alt=""/>
                            )}
                        </div>
                    </Actions>
                </Content>
            </NavContainer>
        </Header>
    );
}

export default Navbar;
