import React, { useEffect } from "react";
import "./styles/App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { path } from "./routes";
import LandingPage from "./pages/LandingPage";
import FAQ from "./pages/FAQ";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import ChatPage from "./pages/Chat";
import Upload from "./pages/Upload";
import Settings from "./pages/SettingsPage";
import GenerateID from "./pages/GenerateID";
import APIKeys from "./pages/APIKeys";
import BillingInfo from "./pages/BillingInfo";
import BillingUpgrade from "./pages/BillingUpgrade";
import AuthGuard from "./components/AuthGuard";
import { TRACKING_ID } from "./config/config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import ReactGA from "react-ga4";
import Spinner from "./components/Spinner";
import SuspenseFallback from "./components/SuspenseFallback";
ReactGA.initialize(TRACKING_ID);

export function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    let path =
      window.location.pathname === "/" ? "/home" : window.location.pathname;
    ReactGA.send(path + window.location.search);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}

const Conversations = React.lazy(() => import("./pages/Conversations"));
const Documents = React.lazy(() => import("./pages/Documents"));
function App() {
  // const queryClient = useQueryClient();
  // const { setPurchaseComplete } = useSubscription();
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   window.createLemonSqueezy();
  //   window.LemonSqueezy.Setup({
  //     eventHandler: (event: any) => {
  //       if (event.event === "Checkout.Success") {
  //         // Close the payment modal and send a message
  //         window.LemonSqueezy.Url.Close();
  //         const lemonSqueezyLoader = document.getElementsByClassName(
  //           "lemonsqueezy-loader"
  //         );
  //         const body = document.querySelector("body");

  //         for (let i = 0; i < lemonSqueezyLoader.length; i++) {
  //           lemonSqueezyLoader[i].remove();
  //         }

  //         body?.classList.remove("lemonsqueezy-loading");
  //         message.success("Subscription successful.", 7);
  //         if (pathname !== "/") {
  //           setPurchaseComplete(true);
  //         }
  //         setTimeout(() => {
  //           queryClient.invalidateQueries("fetchCurrentUser");
  //           queryClient.invalidateQueries("userSubscription");
  //         }, 3000);
  //       } else if (event.event === "PaymentMethodUpdate.Updated") {
  //         message.success("Your payment method has been updated");
  //         setTimeout(() => {
  //           queryClient.invalidateQueries("userSubscription");
  //         }, 3000);
  //       }
  //     },
  //   });
  // }, []);

  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path={path.home} element={<LandingPage />} />
        <Route path={path.FAQs} element={<FAQ />} />
        <Route path={path.PrivacyPolicy} element={<Privacy />} />
        <Route path={path.TermsAndCondition} element={<Terms />} />
        <Route path={path.chat} element={<ChatPage />} />
        <Route path={path.upload} element={<Upload />} />
        <Route
          path={path.conversations}
          element={
            <React.Suspense
              fallback={
                <SuspenseFallback>
                  <Spinner />
                </SuspenseFallback>
              }
            >
              <Conversations />
            </React.Suspense>
          }
        />
        <Route element={<AuthGuard />}>
          <Route
            path={path.documents}
            element={
              <React.Suspense
                fallback={
                  <SuspenseFallback>
                    <Spinner />
                  </SuspenseFallback>
                }
              >
                <Documents />
              </React.Suspense>
            }
          />
          <Route path={path.settings} element={<Settings />} />
          <Route path={path.gptUpload} element={<GenerateID />} />
          <Route path={path.apiKeys} element={<APIKeys />} />
          <Route path={path.billingInfo} element={<BillingInfo />} />
          <Route path={path.billingUpgrade} element={<BillingUpgrade />} />
        </Route>
        <Route path={"*"} element={<LandingPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        className={"app__toast"}
        bodyClassName={"app__toast__body"}
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={false}
        rtl={false}
        draggable
        pauseOnFocusLoss
        theme="light"
      />
    </div>
  );
}

export default App;
