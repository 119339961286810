/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Dropdown, Layout} from "antd";
import AppSidebar from "../components/AppSidebar";
import DeveloperSidebar from "../components/DeveloperSidebar";
import {Content} from "antd/es/layout/layout";
import {ArrowLeft, ArrowUpRight, CaretDown} from "@phosphor-icons/react";
import APIKeysList from "../components/APIKeysList";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CreateAPIKeyModal from "../components/Modals/CreateAPIKeyModal";
import {listApiKey, getCurrentUser} from "../services/devapi";
import {Helmet} from 'react-helmet';

const DevContent = styled(Content)`
  padding-inline: 20px;
  padding-top: 15px;
  padding-bottom: 45px;
  background: #ffffff;

  @media (min-width: 576px) {
    padding-inline: 40px;
  }

  @media (min-width: 992px) {
    padding-block: 45px;
    padding-inline: 40px;
  }
  @media (min-width: 1280px) {
    padding-inline: 110px;
  }
`;

const TitleSection = styled.section`
  display: none;

  h1 {
    color: #141718;
    font-family: Satoshi, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1.6px;
  }

  a {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid var(--black, #000);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #000;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const HeaderSection = styled.section`
  padding-bottom: 20px;
  border-bottom: solid 1px #eaecf0;

  h2 {
    color: #101828;
    font-family: Satoshi, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  p {
    color: #475467;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  @media (min-width: 992px) {
    margin-top: 50px;
  }
`;

const TabGroup = styled.div`
  display: inline-flex;
`;

const Tab = styled.button<{ $active: boolean }>`
  padding: 10px 16px;
  border: 0.5px solid transparent;
  color: ${(props) => (props.$active ? "#ffffff" : "#344054")};
  background: ${(props) => (props.$active ? "#000000" : "#ffffff")};
  cursor: pointer;

  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:first-child {
    border-start-start-radius: 8px;
    border-end-start-radius: 8px;
    border-color: ${(props) => (props.$active ? "transparent" : "#d0d5dd")};
  }

  &:last-child {
    border-start-end-radius: 8px;
    border-end-end-radius: 8px;
    border-color: ${(props) => (props.$active ? "transparent" : "#d0d5dd")};
  }
`;

const TabSection = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    gap: unset;
    justify-content: space-between;
  }
`;

const NewKeyButton = styled.button`
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid #000;
  background: #000;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: #fff;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ContentLayout = styled(Layout)`
  @media (min-width: 992px) {
    margin-left: 362px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background: #050505;
  padding-inline: 30px;
  padding-block: 30px;

  position: sticky;
  top: 0;
  z-index: 100;

  button {
    border: none;
    background: none;
    cursor: pointer;

    svg {
      display: block;
    }
  }

  p {
    color: #fff;
    font-family: Satoshi, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-left: 22px;
  }

  a {
    margin-left: auto;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #ffffff;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

const MobilePageSwitcher = styled.button`
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
  width: 100%;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (min-width: 992px) {
    display: none;
  }
`;
let lk = 1;

function lastkey(key: boolean) {
    if (!key) {
        lk = Math.floor(Math.random() * (20 - 1)) + 1;
        return lk;
    } else {
        return lk;
    }
}

// million-ignore
export default function APIKeys() {
    const [selectedTab, setSelectedTab] = useState<"test" | "prod">("prod");
    const [showAPIKeyModal, setShowAPIKeyModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [keysData, setKeysData] = useState([{key_type: ""}]);
    const [userData, setUserData] = useState({status: "", roles: ["", ""]});
    const usageExausted = (type: String, max: number) => {
        if (keysData.filter((item: { key_type: String; }) => item.key_type === type).length >= max) {
            return true;
        } else {
            return false;
        }
    }
    const updater = async () => {
        const data = await getCurrentUser();
        setKeysData(data.keys);
    }

    useEffect(() => {
        listApiKey().then((data) => {
            setKeysData(data);
        });
        getCurrentUser().then((data) => {
            setUserData(data.data);
        });

    }, []);
    return (
        <div>
            <Helmet>
                <meta name="description"
                      content="AskYourPDF API: Powering Seamless documents Interactions with Advanced
                      AI Capabilities. Our API empowers developers with the ability to programmatically
                      extract valuable information from PDF files and leverage it to create custom chatbots."
                />
            </Helmet>
            <Layout hasSider={true} style={{minHeight: "100vh"}}>
                <AppSidebar/>
                <DeveloperSidebar/>
                <ContentLayout>
                    <Header>
                        <button
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <ArrowLeft size={20} color="#ffffff"/>
                        </button>
                        <p>Developers</p>
                        <a target="_blank" rel="noreferrer noopener"
                           href="https://docs.askyourpdf.com/askyourpdf-docs/">
                            View Docs
                            <ArrowUpRight size={20} color="#ffffff"/>
                        </a>
                    </Header>
                    <DevContent>
                        <TitleSection>
                            <h1>API Keys</h1>
                            <a target="_blank" rel="noreferrer noopener"
                               href="https://docs.askyourpdf.com/askyourpdf-docs/">
                                View Documentation
                                <ArrowUpRight size={20} color="#000000"/>
                            </a>
                        </TitleSection>
                        <HeaderSection>
                            <Dropdown
                                menu={{
                                    items: [
                                        {key: 1, label: <Link to="/apikeys" replace={true}>API Keys</Link>},
                                        {key: 2, label: <Link to="/billing" replace={true}>Billing</Link>},
                                    ],
                                    style: {fontFamily: "Inter, sans-serif"},
                                }}
                                trigger={["click"]}
                            >
                                <MobilePageSwitcher>
                                    {location.pathname === "/apikeys" ? "API Keys" : "Billing"}
                                    <CaretDown size={20} color="#667085"/>
                                </MobilePageSwitcher>
                            </Dropdown>
                            <h2>API Management</h2>
                            <p>API keys let you connect seamlessly with our APIs</p>
                        </HeaderSection>
                        <TabSection>
                            <TabGroup>
                                <Tab
                                    $active={selectedTab === "test"}
                                    onClick={() => {
                                        setSelectedTab("test");
                                    }}
                                >
                                    Test Keys
                                </Tab>
                                <Tab
                                    $active={selectedTab === "prod"}
                                    onClick={() => {
                                        setSelectedTab("prod");
                                    }}
                                >
                                    Production Keys
                                </Tab>
                            </TabGroup>
                            <NewKeyButton
                                onClick={() => {
                                    if (selectedTab === "test") {
                                        if (usageExausted("DEV", 2)) {
                                            return;
                                        }
                                    } else {
                                        if (!userData.roles.some((i: any) => ["api_premium", "api_enterprise"].includes(i))) {
                                            return;
                                        }
                                    }
                                    setShowAPIKeyModal(true);
                                }}
                                disabled={selectedTab === "test" ? (usageExausted("DEV", 2)) : !userData.roles.some((i: any) => ["api_premium", "api_enterprise"].includes(i))}
                            >
                                + New API Key
                            </NewKeyButton>
                        </TabSection>
                        {userData.status !== "" ? (
                            <APIKeysList onUpdate={updater} apiKeys={keysData} keyType={selectedTab}
                                         key={lastkey(showAPIKeyModal)} userData={userData}/>) : (<></>)}
                    </DevContent>
                </ContentLayout>
                <CreateAPIKeyModal onUpdate={updater} open={showAPIKeyModal} setOpen={setShowAPIKeyModal}
                                   keyType={selectedTab === "test" ? "DEV" : "PROD"}/>
            </Layout>
        </div>
    );
}
