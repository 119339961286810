import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConversationsProvider } from "./providers/ConversationsProvider";
import { AuthProvider } from "./providers/AuthProvider";
import { DocumentsProvider } from "./providers/DocumentsProvider";
import { SubscriptionProvider } from "./providers/SubscriptionProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthProvider>
        <ConversationsProvider>
          <DocumentsProvider>
            <SubscriptionProvider>
              <App />
            </SubscriptionProvider>
          </DocumentsProvider>
        </ConversationsProvider>
      </AuthProvider>
    </BrowserRouter>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
