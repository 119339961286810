import styled from "styled-components";
import AppSidebar from "../components/AppSidebar";
import {
  Layout,
  Space,
  Table,
  Row,
  Col,
  Tag,
  Grid,
  message,
  Breakpoint,
} from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "../img/Arrowdown.svg";
import { ReactComponent as DotsIcon } from "../img/DotsDropDown.svg";
import { ArrowLeft, XCircle } from "@phosphor-icons/react";
import {
  DeleteModal,
  ModalContentStyles,
} from "../components/Modals/DocumentDeleteModal";
import { useMutation, useQueryClient } from "react-query";
import {
  ActionType,
  SubType,
  cancelUserSubscription,
  getSubscriptionDetails,
  manageSubscription,
} from "../services/payment";
import { useAuth } from "../providers/AuthProvider";
import Spinner from "../components/Spinner";
import {
  PAID_ROLES,
  UNPAID_ROLES,
  UserPlan,
  WEB_SUBSCRIPTION_PLAN_TYPE,
} from "../config/config";
import PaymentModal from "../components/Modals/PaymentModal";
import { useSubscription } from "../providers/SubscriptionProvider";
import CardBrandIcon from "../components/CardBrandIcon";
import {
  calculateAnalytics,
  formatPlan,
  getUserConversationLimit,
  getUserRole,
} from "../utils/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentSuccessModal from "../components/Modals/PaymentSuccessModal";
import { alerts } from "../utils/alerts";

const { useBreakpoint } = Grid;

type StatusValue = "past_due" | "active" | "cancel";
interface StatusConfig {
  badgeContent: JSX.Element;
}

const StyledTag = styled(Tag)`
  display: flex;
  padding: 2px 8px 2px 6px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 16px;
  width: 80px;
`;
const statusConfig: Record<StatusValue, StatusConfig> = {
  past_due: {
    badgeContent: (
      <StyledTag
        style={{
          border: "1.5px solid #D92D20",
          background: "#fff",
          color: "#D92D20",
        }}
      >
        {<CloseOutlined style={{ color: "#D92D20", margin: "4px" }} />}Unpaid
      </StyledTag>
    ),
  },
  active: {
    badgeContent: (
      <StyledTag
        color="#079455"
        style={{
          border: "1.5px solid #079455",
          background: "#ECFDF3",
          color: "#079455",
        }}
      >
        {<CheckOutlined style={{ color: "#079455", margin: "4px" }} />}Paid
      </StyledTag>
    ),
  },
  cancel: {
    badgeContent: (
      <StyledTag
        color="#079455"
        style={{
          border: "1.5px solid #079455",
          background: "#ECFDF3",
          color: "#079455",
        }}
      >
        {<CheckOutlined style={{ color: "#079455", margin: "4px" }} />}Paid
      </StyledTag>
    ),
  },
};

const defaultStatusConfig: StatusConfig = {
  badgeContent: (
    <StyledTag color="#000">{/* The status will be rendered here */}</StyledTag>
  ),
};

const columns = [
  {
    title: (
      <Row align={"top"}>
        <p>Invoice</p>
      </Row>
    ),
    dataIndex: "created_at",
    key: "created_at",
    render: (text: string) => (
      <Space>
        <h3>
          {new Date(Number(text) * 1000).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
          })}
        </h3>
      </Space>
    ),
    sorter: (a: any, b: any) =>
      new Date(a.created_at * 1000).getTime() -
      new Date(b.created_at * 1000).getTime(),
    showSorterTooltip: false,
  },
  {
    title: (
      <Row align={"top"}>
        <p>Status</p>
      </Row>
    ),
    dataIndex: "subscription_status",
    key: "subscription_status",
    render: (text: StatusValue) => {
      const { badgeContent } = statusConfig[text] || defaultStatusConfig;
      return badgeContent;
    },
  },
  {
    title: (
      <Row align={"top"}>
        <p>Amount</p>
      </Row>
    ),
    dataIndex: "amount",
    key: "amount",
    render: (text: string) => (
      <Space>
        <p>${text}</p>
      </Space>
    ),
  },
  {
    title: (
      <Row align={"top"}>
        <p>Plan</p>
      </Row>
    ),
    dataIndex: "variant_name",
    key: "variant_name",
    render: (text: string) => (
      <Space>
        <p>{formatPlan(text)}</p>
      </Space>
    ),
    responsive: ["md"] as Breakpoint[],
  },
];

// million-ignore
export default function SettingsPage() {
  const [isActive, setIsActive] = useState(true);
  const [confirmDownGrade, setConfirmDownGrade] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { xs } = useBreakpoint();
  const { userToken, userDetails, userAnalytics, isUserAnalyticsLoading } =
    useAuth();

  const navigate = useNavigate();

  const [isRenewingPlan, setIsRenewingPlan] = useState(false);
  const [isManagingPayment, setIsManagingPayment] = useState(false);
  const [isSwitchingPlan, setIsSwitchingPlan] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const {
    userSubscriptionInfo: subscriptionInfo,
    subscriptionStatus,
    isSubscriptionError,
    subscriptionError,
    billingHistory,
    billingStatus,
    isBillingFetching,
    totalBillingRecords,
    setBillingPage,
    billingPageSize,
  } = useSubscription();

  const is404Error = isSubscriptionError && subscriptionError.status === 404;

  const webBillingHistory = billingHistory?.history?.filter(
    (history: any) => history.plan_type === WEB_SUBSCRIPTION_PLAN_TYPE
  );

  const WEB_SUBSCRIPTION_TYPE = SubType.WEB;

  useEffect(() => {
    if (isSubscriptionError && !is404Error) {
      if (subscriptionError.message) {
        alerts.error("Failed to load subscriptions", `${subscriptionError.message}`);
      } else {
        alerts.error("Failed to load subscriptions",
          "An error occurred while loading your subscription. Please try again",
        );
      }
    }
  }, [isSubscriptionError, subscriptionError]);

  const [searchParams] = useSearchParams();

  const paymentStatus = searchParams.get("payment");

  useEffect(() => {
    if (paymentStatus === "success") {
      setShowSuccessModal(true);
    }
  }, [paymentStatus]);

  // const cancelSubscriptionMutation = useMutation(cancelUserSubscription, {
  //   onSuccess: (data) => {
  //     queryClient.invalidateQueries("userSubscription");
  //     message.success("Your subscription has been cancelled");
  //     setConfirmDownGrade(false);
  //   },
  //   onError: (error) => {
  //     message.error(
  //       "An error occurred while cancelling your subscription. Please try again",
  //       10
  //     );
  //   },
  // });

  const manageSubscriptionMutation = useMutation(manageSubscription, {
    onSuccess: (data) => {
      window.location.href = data.url;
    },
    onError: (error) => {
      alerts.error("Error", "An error occurred. Please try again");
    },
    onSettled: () => {
      setIsRenewingPlan(false);
      setIsManagingPayment(false);
      setIsSwitchingPlan(false);
    },
  });

  const handleManageSubscription = async (subType: SubType, action?: ActionType) => {
    if (subscriptionInfo === undefined || userToken === undefined) {
      return;
    }

    manageSubscriptionMutation.mutate({token: userToken, subType, action});
  };

  // const handleCancelSubscription = () => {
  //   if (subscriptionInfo === undefined) {
  //     return;
  //   }

  //   cancelSubscriptionMutation.mutate({
  //     subscriptionID: subscriptionInfo.subscription_id,
  //     token: userToken,
  //   });
  // };

  // const handleUpdatePaymentMethod = async () => {
  //   try {
  //     if (subscriptionInfo === undefined || userToken === undefined) {
  //       return;
  //     }

  //     const subscription = await getSubscriptionDetails(
  //       subscriptionInfo.subscription_id,
  //       userToken
  //     );

  //     const paymentMethodUrl = subscription.payment_method_url;

  //     if (paymentMethodUrl) {
  //       window.LemonSqueezy.Url.Open(paymentMethodUrl);
  //     }
  //   } catch (err) {
  //     message.error("An error occurred. Please try again");
  //   }
  // };

  const userRole = getUserRole(userDetails);

  const isSubscriptionExpired = subscriptionInfo ? subscriptionInfo.subscription_status === "canceled" && new Date().getTime() > new Date(subscriptionInfo.renewal_date*1000).getTime()  : false;
  const isSubscriptionCanceled = subscriptionInfo ? subscriptionInfo.subscription_status === "canceled" && new Date().getTime() < new Date(subscriptionInfo.renewal_date*1000).getTime() : false;


  return (
    <>
      {subscriptionStatus === "loading" && (
        <LoadingOverlay>
          <Spinner style={{ width: "50px" }} />
        </LoadingOverlay>
      )}
      <SettingsContainer hasSider>
        <AppSidebar />
        <DeleteModal
          open={confirmDownGrade}
          maskStyle={{
            background: "rgba(0, 0, 0, 0.60)",
            backdropFilter: "blur(8px)",
          }}
          footer={null}
          width={350}
          closable={false}
          onCancel={() => {}}
        >
          <ModalContentStyles>
            <h1>Downgrade Account</h1>
            <p>
              Are you sure you want to downgrade your account? You'll have
              access to your {userRole} features until the end of your billing
              period on{" "}
              {subscriptionInfo
                ? new Date(
                    subscriptionInfo.renewal_date * 1000
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })
                : ""}
            </p>
            <div>
              <button
                onClick={() => setConfirmDownGrade(!confirmDownGrade)}
                disabled={manageSubscriptionMutation.isLoading}
              >
                Go Back
              </button>
              <button
                onClick={() => {
                  handleManageSubscription(
                    WEB_SUBSCRIPTION_TYPE,
                    ActionType.SUBSCRIPTION_CANCEL
                  );
                }}
                disabled={manageSubscriptionMutation.isLoading}
              >
                Downgrade{" "}
                {manageSubscriptionMutation.isLoading && (
                  <Spinner style={{ width: "24px" }} />
                )}
              </button>
            </div>
          </ModalContentStyles>
        </DeleteModal>
        <Col span={24}>
          <PageTitleContainer
            justify={"space-between"}
            align={"middle"}
            style={{ zIndex: 11, position: "relative" }}
          >
            <h1>Settings</h1>
            <Col
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              {xs ? (
                <ArrowLeft size={24} color="#fff" />
              ) : (
                <XCircle size={36} />
              )}
            </Col>
          </PageTitleContainer>
          <PlansContainer>
            <div>
              <h2>Account plans</h2>
              <p>Pick an account plan that fits your workflow.</p>
            </div>
          </PlansContainer>
          <InnerPlansContainer gutter={10} justify={"space-between"}>
            <Col xs={24} sm={24} xl={6}>
              <h3>Current plan</h3>
              <p>
                If you choose to downgrade at any point. Your subscription will
                be active until the end of your next billing cycle.
              </p>
            </Col>
            <PlanCardsContainer xs={24} sm={24} xl={16}>
              <div
                className="contain"
                onClick={() => {
                  if (subscriptionStatus === "error") {
                    return;
                  }

                  if (userRole === "free") {
                    return;
                  }

                  if (isSubscriptionCanceled) {
                    setIsRenewingPlan(true);
                    handleManageSubscription(WEB_SUBSCRIPTION_TYPE);
                    return;
                  }
                  setConfirmDownGrade(!confirmDownGrade);
                }}
              >
                <PlanCard
                  className={
                    userRole === "free" || is404Error ? "active" : "inactive"
                  }
                >
                  <h3>
                    Free Plan <span>$0/month</span>
                  </h3>
                  <p>
                    Free tier usage applicable - 100 page per document & 50
                    questions daily
                  </p>
                </PlanCard>
                {subscriptionStatus !== "error" ? (
                  userRole === "free" ? (
                    <ActivePlan style={{ color: "#fff" }} />
                  ) : isSubscriptionCanceled ? (
                    <DowngradeButton
                      disabled={manageSubscriptionMutation.isLoading}
                    >
                      Renew Canceled Plan{" "}
                      {isRenewingPlan && (
                        <Spinner
                          style={{ width: "24px", verticalAlign: "middle" }}
                        />
                      )}
                    </DowngradeButton>
                  ) : (
                    <DowngradeButton>
                      {" "}
                      Cancel Current Subscription
                    </DowngradeButton>
                  )
                ) : is404Error ? (
                  // show this is your current plan for users without subscription
                  <ActivePlan style={{ color: "#fff" }} />
                ) : (
                  <></>
                )}
              </div>
              <div
                className="contain"
                onClick={() => {
                  // show the upgrade modal
                  // don't allow it to show if the user is premium and their status is not expired/canceled
                  if (
                    subscriptionInfo &&
                    !isSubscriptionExpired &&
                    userRole === UserPlan.PREMIUM
                  ) {
                    return;
                  }

                  if (
                    subscriptionInfo &&
                    !isSubscriptionExpired &&
                    userRole !== "free"
                  ) {
                    setIsSwitchingPlan(true);
                    handleManageSubscription(
                      WEB_SUBSCRIPTION_TYPE,
                      ActionType.SUBSCRIPTION_UPDATE
                    );
                    return;
                  }
                  setShowPaymentModal(true);
                }}
              >
                <PlanCard
                  className={
                    subscriptionInfo &&
                    !isSubscriptionExpired &&
                    userRole === UserPlan.PREMIUM
                      ? "active"
                      : "inactive"
                  }
                >
                  <h3>
                    Premium Plan{" "}
                    <span>$4.99/month or $3.99/month (Billed Annually)</span>
                    {isSubscriptionCanceled &&
                      userRole === UserPlan.PREMIUM && (
                        <PlanBadge>Canceled</PlanBadge>
                      )}
                  </h3>
                  <ul>
                    <li>Includes up to 2,500 pages per doc (max 30mb)</li>
                    <li>50 documents per day</li>
                    <li>1200 questions / day</li>
                    <li>Share Customizable link</li>
                    <li>API Access</li>
                    <li>Chrome Extension Access</li>
                  </ul>
                </PlanCard>
                {subscriptionStatus !== "error" ? (
                  subscriptionInfo &&
                  !isSubscriptionExpired &&
                  userRole === UserPlan.PREMIUM ? (
                    <ActivePlan style={{ color: "#fff" }} />
                  ) : userRole === UserPlan.PRO ||
                    userRole === UserPlan.ENTERPRISE ? (
                    <UpgradeButton
                      style={{ color: "#fff" }}
                      disabled={manageSubscriptionMutation.isLoading}
                    >
                      Switch To Premium
                      {isSwitchingPlan && (
                        <Spinner
                          style={{ width: "24px", verticalAlign: "middle" }}
                        />
                      )}
                    </UpgradeButton>
                  ) : (
                    <UpgradeButton style={{ color: "#fff" }} disabled={manageSubscriptionMutation.isLoading}>
                      Upgrade To Premium
                      {isSwitchingPlan && (
                        <Spinner
                          style={{ width: "24px", verticalAlign: "middle" }}
                        />
                      )}
                    </UpgradeButton>
                  )
                ) : is404Error ? (
                  // show upgrade button here for users without subscriptions
                  <UpgradeButton style={{ color: "#fff" }}>
                    Upgrade To Premium
                  </UpgradeButton>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="contain"
                onClick={() => {
                  // show the upgrade modal
                  // don't allow it to show if the user is premium and their status is not expired/canceled
                  if (
                    subscriptionInfo &&
                    !isSubscriptionExpired &&
                    userRole === UserPlan.PRO
                  ) {
                    return;
                  }

                  if (
                    subscriptionInfo &&
                    !isSubscriptionExpired &&
                    userRole !== "free"
                  ) {
                    setIsSwitchingPlan(true);
                    handleManageSubscription(
                      WEB_SUBSCRIPTION_TYPE,
                      ActionType.SUBSCRIPTION_UPDATE
                    );
                    return;
                  }
                  setShowPaymentModal(true);
                }}
              >
                <PlanCard
                  className={
                    subscriptionInfo &&
                    !isSubscriptionExpired &&
                    userRole === UserPlan.PRO
                      ? "active"
                      : "inactive"
                  }
                >
                  <h3>
                    Pro Plan{" "}
                    <span>$12.99/month or $7.99/month (Billed Annually)</span>
                    {isSubscriptionCanceled && userRole === UserPlan.PRO && (
                      <PlanBadge>Canceled</PlanBadge>
                    )}
                  </h3>
                  <ul>
                    <li>Includes up to 6,000 pages per doc (max 90mb)</li>
                    <li>150 documents per day</li>
                    <li>Unlimited questions / day</li>
                    <li>Share Customizable link</li>
                    <li>Priority support</li>
                    <li>OCR support (Coming Soon)</li>
                    <li>Longer Reply Length</li>
                    <li>Priority access to new features</li>
                  </ul>
                </PlanCard>
                {subscriptionStatus !== "error" ? (
                  subscriptionInfo &&
                  !isSubscriptionExpired &&
                  userRole === UserPlan.PRO ? (
                    <ActivePlan style={{ color: "#fff" }} />
                  ) : userRole === UserPlan.ENTERPRISE ? (
                    <UpgradeButton
                      style={{ color: "#fff" }}
                      disabled={manageSubscriptionMutation.isLoading}
                    >
                      Switch To Pro{" "}
                      {isSwitchingPlan && (
                        <Spinner
                          style={{ width: "24px", verticalAlign: "middle" }}
                        />
                      )}
                    </UpgradeButton>
                  ) : (
                    <UpgradeButton
                      style={{ color: "#fff" }}
                      disabled={manageSubscriptionMutation.isLoading}
                    >
                      Upgrade To Pro{" "}
                      {isSwitchingPlan && (
                        <Spinner
                          style={{ width: "24px", verticalAlign: "middle" }}
                        />
                      )}
                    </UpgradeButton>
                  )
                ) : is404Error ? (
                  // show upgrade button here for users without subscriptions
                  <UpgradeButton style={{ color: "#fff" }}>
                    Upgrade To Pro
                  </UpgradeButton>
                ) : (
                  <></>
                )}
              </div>
              <div className="contain">
                <PlanCard
                  className={
                    !isSubscriptionExpired &&
                    userDetails?.roles?.includes("enterprise")
                      ? "active"
                      : "inactive"
                  }
                >
                  <h3>
                    Enterprise plan{" "}
                    {isSubscriptionCanceled &&
                      userRole === UserPlan.ENTERPRISE && (
                        <PlanBadge>Canceled</PlanBadge>
                      )}
                  </h3>
                  <ul>
                    <li>Unlimited pages</li>
                    <li>Unlimited file size</li>
                    <li>Unlimited doc uploads a day</li>
                    <li>Unlimited questions / day</li>
                    <li>Share Customizable link</li>
                    <li>Dedicated 24/7 Support</li>
                  </ul>
                </PlanCard>
                {subscriptionStatus !== "error" ? (
                  !isSubscriptionExpired &&
                  userDetails?.roles?.includes("enterprise") ? (
                    <ActivePlan style={{ color: "#fff" }} />
                  ) : (
                    <a href="mailto:entreprise@askyourpdf.com">
                      <UpgradeButton style={{ color: "#fff" }}>
                        Contact Us
                      </UpgradeButton>
                    </a>
                  )
                ) : is404Error ? (
                  // Show Contact Us button here
                  <a href="mailto:entreprise@askyourpdf.com">
                    <UpgradeButton style={{ color: "#fff" }}>
                      Contact Us
                    </UpgradeButton>
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </PlanCardsContainer>
          </InnerPlansContainer>
          <PlansContainer>
            <div>
              <h2>Payment Method, Billing History & Usage</h2>
              <p>Manage your payment method and billing history</p>
            </div>
          </PlansContainer>
          {subscriptionStatus !== "error" &&
            subscriptionInfo &&
            subscriptionInfo?.card_brand && (
              <InnerPlansContainer gutter={10} justify={"space-between"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <h3>Payment Method</h3>
                </Col>

                <Col xs={24} sm={24} md={24} lg={16}>
                  <PaymentPlansContainer
                    style={{ borderBottom: "1px solid #EAECF0" }}
                  >
                    <div>
                      <CardBrandIcon
                        cardBrand={subscriptionInfo?.card_brand}
                        style={{ width: "48px" }}
                      />
                      <p>
                        {subscriptionInfo
                          ? subscriptionInfo.card_brand
                              .charAt(0)
                              .toUpperCase() +
                            subscriptionInfo.card_brand.slice(1)
                          : ""}{" "}
                        ending in{" "}
                        <span>
                          *
                          {subscriptionInfo
                            ? subscriptionInfo.card_last_four
                            : ""}
                        </span>
                      </p>
                    </div>
                    <div className="end">
                      <PaymentButton
                        onClick={() => {
                          setIsManagingPayment(true);
                          handleManageSubscription(
                            WEB_SUBSCRIPTION_TYPE,
                            ActionType.PAYMENT_UPDATE
                          );
                        }}
                        disabled={manageSubscriptionMutation.isLoading}
                      >
                        {isManagingPayment ? (
                          <>
                            Please Wait{" "}
                            <Spinner
                              style={{ width: "18px", verticalAlign: "middle" }}
                            />
                          </>
                        ) : (
                          "Manage Payment Method"
                        )}
                      </PaymentButton>
                    </div>
                  </PaymentPlansContainer>
                </Col>
              </InnerPlansContainer>
            )}
          <InvoiceSection gutter={10} justify={"space-between"}>
            <Col xs={24} sm={24} md={6} lg={6}>
              <h3>Invoice History</h3>
              <p>
                Please reach out to our team via billing@askyourpdf.com if you
                have any concerns about billing
              </p>
            </Col>
            <Col xs={24} sm={24} md={18} lg={16}>
              <TableContainer>
                <Table
                  dataSource={webBillingHistory}
                  columns={columns}
                  pagination={{
                    defaultCurrent: 1,
                    total: totalBillingRecords,
                    pageSize: billingPageSize,
                    onChange: (page) => {
                      setBillingPage(page);
                    },
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                  }}
                  loading={{
                    spinning: isBillingFetching,
                    indicator: (
                      <div>
                        <Spinner style={{ width: "25px" }} />
                      </div>
                    ),
                  }}
                />
                {billingStatus === "error" && (
                  <p style={{ color: "#B42318" }}>
                    Failed to fetch billing history. Please try again.
                  </p>
                )}
              </TableContainer>
            </Col>
          </InvoiceSection>
          <InvoiceSection
            gutter={10}
            justify={"space-between"}
            align={"middle"}
            wrap
          >
            <Col xs={24} sm={24} md={4} lg={6}>
              <h3>Usage</h3>
              <p>Track your Usage</p>
            </Col>
            <UploadedDocs xs={24} sm={24} md={24} lg={24} xl={8}>
              <section>
                <Col span={12}>
                  <p>Uploaded Docs</p>
                  <h1>{userAnalytics ? userAnalytics.number_of_pdfs : "-"}</h1>
                </Col>
                <Col
                  className="end"
                  span={12}
                  style={{ alignSelf: "flex-end" }}
                >
                  {/* <DotsIcon /> */}
                  <StyledTag
                    style={{
                      width: "120px",
                      background: "#ECFDF3",
                      color: "#079455",
                    }}
                  >
                    {userAnalytics?.number_of_pdfs
                      ? `${calculateAnalytics(userAnalytics.number_of_pdfs)}%`
                      : `-%`}{" "}
                    Quota Used
                  </StyledTag>
                </Col>
              </section>
            </UploadedDocs>
            <UploadedDocs xs={24} sm={24} md={24} lg={24} xl={8}>
              <section>
                <Col span={12}>
                  <p>Conversations</p>
                  <h1>
                    {userAnalytics
                      ? userAnalytics.number_of_conversations
                      : "-"}
                  </h1>
                </Col>
                <Col
                  className="end"
                  span={12}
                  style={{ alignSelf: "flex-end" }}
                >
                  {/* <DotsIcon /> */}
                  <StyledTag
                    style={{
                      width: "120px",
                      background: "#ECFDF3",
                      color: "#079455",
                    }}
                  >
                    {userAnalytics?.number_of_conversations !== undefined
                      ? `${calculateAnalytics(
                          `${
                            userAnalytics.number_of_conversations
                          } / ${getUserConversationLimit(userDetails)}`
                        )}%`
                      : `-%`}{" "}
                    Quota Used
                  </StyledTag>
                </Col>
              </section>
            </UploadedDocs>
          </InvoiceSection>
          <InvoiceSection
            gutter={10}
            justify={"space-between"}
            align={"middle"}
            wrap
          >
            <Col xs={24} sm={24} md={4} lg={6}></Col>
            <UploadedDocs xs={24} sm={24} md={24} lg={24} xl={8}>
              <section>
                <Col span={12}>
                  <p>Questions</p>
                  <h1>
                    {userAnalytics ? userAnalytics.number_of_questions : "-"}
                  </h1>
                </Col>
                <Col
                  className="end"
                  span={12}
                  style={{ alignSelf: "flex-end" }}
                >
                  {/* <DotsIcon /> */}
                  <StyledTag
                    style={{
                      width: "120px",
                      background: "#ECFDF3",
                      color: "#079455",
                    }}
                  >
                    {userAnalytics?.number_of_questions
                      ? `${calculateAnalytics(
                          userAnalytics.number_of_questions
                        )}%`
                      : `-%`}{" "}
                    Quota Used
                  </StyledTag>
                </Col>
              </section>
            </UploadedDocs>
            <UploadedDocs xs={24} sm={24} md={24} lg={24} xl={8}>
              <section>
                <Col span={12}>
                  <p>Questions (Plugin)</p>
                  <h1>
                    {userAnalytics
                      ? userAnalytics.number_of_questions_plugin
                      : "-"}
                  </h1>
                </Col>
                <Col
                  className="end"
                  span={12}
                  style={{ alignSelf: "flex-end" }}
                >
                  {/* <DotsIcon /> */}
                  <StyledTag
                    style={{
                      width: "120px",
                      background: "#ECFDF3",
                      color: "#079455",
                    }}
                  >
                    {userAnalytics?.number_of_questions_plugin
                      ? `${calculateAnalytics(
                          userAnalytics.number_of_questions_plugin
                        )}%`
                      : `-%`}{" "}
                    Quota Used
                  </StyledTag>
                </Col>
              </section>
            </UploadedDocs>
          </InvoiceSection>
        </Col>
      </SettingsContainer>
      <PaymentModal open={showPaymentModal} setOpen={setShowPaymentModal} />
      <PaymentSuccessModal
        open={showSuccessModal}
        setOpen={setShowSuccessModal}
      />
    </>
  );
}

const PlanBadge = styled.span<{ $color?: string; $background?: string }>`
  border-radius: 16px;
  padding: 2px 10px;
  background: ${(props) => (props.$background ? props.$background : "#F2F4F7")};
  color: ${(props) => (props.$color ? props.$color : "#344054")} !important;
  text-align: center;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px !important;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(8px);
  z-index: 10;
  height: 100%;
  align-items: center;
`;

const UploadedDocs = styled(Col)`
  section {
    display: flex;
    width: 100%;
    margin: 20px auto;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }
  .end {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
`;
const TableContainer = styled.div`
  width: 100%;

  .ant-table-wrapper .ant-table-column-sorters {
    flex-direction: row;
  }
`;
const SettingsContainer = styled(Layout)`
  min-height: 100vh;
  font-family: Satoshi, sans-serif;
  background: #fff;
  padding: 10px 5.3%;
  @media (max-width: 900px) {
    padding: 0px 40px;
  }
  @media (max-width: 576px) {
    padding: 0px 0px;
  }
`;
const PageTitleContainer = styled(Row)`
  width: 100%;
  height: 100px;
  padding-left: 3%;
  margin-bottom: 10px;
  h1 {
    color: #141718;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1.6px;
  }

  @media (max-width: 576px) {
    background: #000;
    flex-direction: row-reverse;

    h1 {
      text-align: flex-start;
      width: 90%;
      font-size: 30px;
      color: #fff;
    }
  }
`;

const PlansContainer = styled(PageTitleContainer)`
  width: 90%;
  height: auto;
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #eaecf0;
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
  }
  h2 {
    color: #101828;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    width: 85%;
  }
  h3 {
    color: #344054;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  p,
  li {
    color: #475467;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  @media (max-width: 576px) {
    width: 100%;
    background: #fff;
    padding: 0px 20px;
  }
`;
const InnerPlansContainer = styled(PlansContainer)`
  div {
    border: none;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
`;
const PlanCardsContainer = styled(Col)`
  gap: 0px;
  .contain {
    padding: 0px;
    margin: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-item: flex-start;
    gap: 0px;
  }
`;
const PlanCard = styled(Row)`
  width: 100%;
  display: flex;
  min-height: 50px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  padding: 25px;
  border: 1px solid #eaecf0;
  background: #ffffff;
  &.active {
    border: 2px solid #475467;
    background: #f9fafb;
  }
  &.inactive {
    border: 1px solid #eaecf0;
    background: #ffffff;
  }
  span {
    color: #2c2c2c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }
  ul {
    width: 90%;
    margin: 10px auto;
  }
  @media (max-width: 568px) {
    padding: 15px;
    ul {
      width: 80%;
      margin: 0 auto;
    }
  }
`;
const ActivePlan = styled(CheckOutlined)`
  position: absolute;
  right: 20px;
  top: 20px;
  background: #475467;
  border-radius: 50%;
  color: #fff;
  display: grid;
  place-items: center;
  padding: 3px;
  @media (max-width: 645px) {
    display: none;
  }
`;
const UpgradeButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10%;
  right: 20px;
  background: #000000;
  padding: 8px 14px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid var(--black, #000);
  color: #fff;
  cursor: pointer;
  margin-bottom: 7px;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  @media (max-width: 900px) {
    position: relative;
    top: 0;
    right: 0;
    width: 200px;
    height: 40px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const DowngradeButton = styled(UpgradeButton)`
  top: 15px;
  @media (max-width: 900px) {
    top: 0;
    width: 225px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const PaymentButton = styled(UpgradeButton)`
  background: #fff;
  width: 200px;
  position: relative;
  right: 0px;
  top: 0px;
  color: #000;
  @media (max-width: 574px) {
    width: 100%;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const PaymentPlansContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  padding: 5px;
  padding-bottom: 15px;
  .end {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  span {
    font-weight: 700;
  }
  @media (max-width: 574px) {
    flex-wrap: wrap;
    .end {
      align-items: center;
    }
  }
`;
const InvoiceSection = styled(InnerPlansContainer)`
  width: 95%;
  div {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  h1 {
    color: #101828;
    font-size: 34px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    letter-spacing: -0.72px;
    @media (max-width: 480px) {
      font-size: 24px;
      font-weight: 700;
    }
  }
`;
